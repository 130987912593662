import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CustomValidator } from 'src/app/helpers/custom.validator';
import { TranslateService } from '@ngx-translate/core';
import { SwalService } from 'src/app/services/swal.service';
import { FieldDetails } from 'src/app/models/global/FieldDetails';
import { FieldTypes } from 'src/app/models/global/FieldTypes';
import { Attachments } from 'src/app/models/lookups/Attachments';

export interface DialogData {
  title: string;
  titleField: string;
  comment: string;
  record: any;
  serviceTarget: string;
  service: any;
  showClose: boolean;
  showBack: boolean;
  hideSwalMessageSuccess: boolean;
  btnSubmitTitle: string;
  swalMessageSuccess: string;
  commentProperty: string;
  helpersTop: {
    label: string;
    class: string;
  }[];
  helpersMiddle: {
    label: string;
    class: string;
  }[];
  helpersbottom: {
    label: string;
    class: string;
  }[];
  fields: FieldDetails[];
}

@Component({
  selector: 'app-submit-reason',
  templateUrl: './submit-reason.component.html'
})
export class SubmitReasonComponent implements OnInit {
  formGroup: FormGroup = new FormGroup({});
  loading = false;
  isSubmitted = false;
  fields: FieldDetails[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<SubmitReasonComponent>,
    public translate: TranslateService,
    private swalService: SwalService
  ) { }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    let ctr = null;
    if (this.data && this.data.fields && this.data.fields.length) {
      this.fields = this.data.fields;
    } else {
      this.fields.push({
        id: this.data.commentProperty ? this.data.commentProperty : 'comment',
        value: this.data.comment,
        isMandatory: true,
        inputTypeId: FieldTypes.TEXTAREA,
        inputTitle: this.data.titleField,
        fieldLength: 500
      })
    }
    if (this.fields.length) {
      this.fields.forEach(field => {
        if (field.inputTypeId !== FieldTypes.FILE) {
          let inputAnswer = field.value;
          ctr = new FormControl(inputAnswer || null);
          ctr.setValidators(this.setValidation(field, true));
          this.formGroup.addControl(String(field.id), ctr);
        }
      });
    }
  }

  setValidation(field, setValidation) {
    const validations = [];
    if (field.inputTypeId === FieldTypes.NUMBER && field.isMandatory) {
      validations.push(Validators.pattern(/^[0-9]+([,.][0-9]+)?$/));
      if (field.jsonProps.hasARange) {
        validations.push(Validators.min(field.jsonProps.minimumRange));
        validations.push(Validators.max(field.jsonProps.maxRange));
      }
    }
    if (field.inputTypeId === FieldTypes.TEXTAREA && field.isMandatory) {
      validations.push(CustomValidator.noWhiteSpace);
    } else if (field.isMandatory) {
      validations.push(Validators.required);
    }
    return setValidation ? validations : null;
  }

  bindDataToUpdate() {
    if (this.data.comment) {
      this.formGroup.controls.comment.setValue(this.data.comment);
    }
  }

  hasError(errorName: string, controlName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  saveRecord() {
    this.isSubmitted = true;
    if (this.formGroup.valid) {
      this.loading = true;
      if (this.data.serviceTarget) {
        let record = {};
        let data = null;
        const formData = new FormData();
        this.fields.forEach((field: FieldDetails, index: number) => {
          if (field.inputTypeId !== FieldTypes.FILE) {
            record[field.id] = this.formGroup.controls[field.id].value;
          } else {
            if (field && field.value && field.value.length) {
              (field.value as any[]).forEach(file => {
                if (!file.id) {
                  formData.append(field.id, file, file.name);
                }
              });
            }
            console.log(field)
            record[field.deletedKey] = field.deletedAttachments;
          }
        });
        if (this.data.record) {
          record = { ...this.data.record, ...record };
          data = record;
        }
        if (this.fields.some((obj: any) => obj.inputTypeId === FieldTypes.FILE)) {
          formData.append('data', JSON.stringify(record));
          data = formData
        }
        const service = this.data.service;
        service[this.data.serviceTarget](data).subscribe(() => {
          this.loading = false;
          if (!this.data.hideSwalMessageSuccess) {
            this.swalService.swalSuccess(this.data.swalMessageSuccess ? this.data.swalMessageSuccess : this.translate.instant('swalMsg.savedsuccessfully'))
          }
          this.dialogRef.close(true);
        }, err => {
          this.loading = false;
          const errors = JSON.parse(err.detail);
          let message = '';
          if (err.detail) {
            errors.ErrorDTO.forEach((error) => {
              if (error.ErrorCode === 'NotAllowed') {
                message = error.Error;
              }
              if (error.ErrorCode === 'Repeated') {
                this.formGroup.controls[error.ErrorDetail].setErrors({ notUniqe: true });
              }
            });
          } else {
            message = err.title;
          }
          if (message) {
            this.swalService.swalFailed(message);
          }
        });
      } else {
        setTimeout(() => {
          this.loading = false;
          this.dialogRef.close(this.formGroup.controls.comment.value);
        }, 1000);
      }

    }
  }

  backDialog() {
    this.dialogRef.close({ back: true });
  }

  changeVlaue(e) {
    this.formGroup.controls.comment.setValue(null);
  }

}
