import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { CoursesIntegrationService } from 'src/app/services/courses-integration.service';
import { SwalService } from 'src/app/services/swal.service';
import * as saveAs from 'file-saver';

export interface DialogData {
  fileUrl: string;
  fileName: string;
  canDownloadIt: boolean;
  lessonId: number;
}
@Component({
  selector: 'app-files-dialog',
  templateUrl: './files-dialog.component.html',
})
export class FilesDialogComponent implements OnInit {
  url: any;
  loading = false;
  @Output() dataChange = new EventEmitter<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<FilesDialogComponent>,
    public translate: TranslateService,
    private service: CoursesIntegrationService,
    public dialog: MatDialog,
    private swalService: SwalService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    if (this.fileType(this.data.fileUrl) == 'pdf') {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
        `${this.data.fileUrl}#toolbar=0`
      );
    } else if (this.fileType(this.data.fileUrl) == 'pptx') {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://view.officeapps.live.com/op/embed.aspx?src=' +
          this.data.fileUrl
      );
    }
  }

  downloadFile() {
    this.loading = true;
    let model = {
      lessonId: this.data.lessonId,
    };
    this.service.countDownloadLesson(model).subscribe(
      (data) => {
        saveAs(this.data.fileUrl, this.data.fileName);
        this.swalService.swalSuccess();
        this.dataChange.emit(true)
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  fileType(fileName) {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'pdf':
        return 'pdf';
      case 'ppt':
      case 'pptx':
        return 'pptx';
      default:
        return false;
    }
  }
}
