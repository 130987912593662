import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CourseDurationProgress } from 'src/app/models/courses/CourseDurationProgress';
import { ContentTypeEnum } from 'src/app/models/courses/enums/ContentTypeEnum';
import { ModuleStatusEnum } from 'src/app/models/courses/enums/ModuleStatusEnum';
import { CoursesService } from 'src/app/services/Courses.service';

@Component({
  selector: 'app-course-duration-progress',
  templateUrl: './course-duration-progress.component.html'
})
export class CourseDurationProgressComponent implements OnInit {
  @Input() courseId: number;
  @Input() forTrainee = false;
  @Output() closeDetails = new EventEmitter<boolean>();

  dataLoading = false;
  details: CourseDurationProgress = new CourseDurationProgress();
  contentTypeLesson = ContentTypeEnum;
  moduleStatus = ModuleStatusEnum;


  constructor(
    private service: CoursesService
  ) { }

  ngOnInit() {
    this.getDetails();
  }

  getDetails() {
    this.dataLoading = true;
    let serviceTarget = this.service.getCourseSideSheet(this.courseId);
    if (this.forTrainee) {
      serviceTarget = this.service.getCourseSideSheetForTrainee(this.courseId);
    }
    serviceTarget.subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.details = data as CourseDurationProgress;
        }
      },
      error => {
        this.dataLoading = false;
      });
  }


}
