<div *ngIf='dataLoading' class="dataLoading">
  <mat-spinner class="m-auto" diameter="50"></mat-spinner>
</div>

<ng-container *ngIf='!dataLoading'>
  <div class="sidenav-user-toolbar">
    <h4>
      {{"courses.trainerBio"|translate}}
    </h4>
  </div>
  <div class="sidenav-user-container">
    <div class="top_profile">
      <div class="overlay-profile"></div>
      <div class="user_info">
        <div class="user_info_avatar">
          <img *ngIf="details.userImage" [src]="details.userImage" (error)='details.userImage = ""'>
          <span *ngIf="!details.userImage">{{ details.fullName | shortName }}</span>
        </div>
        <h4>{{details.fullName}}</h4>
      </div>
    </div>
    <div class="profile_detail">
      <ul class="list-items border-0 pb-0"
        *ngIf="details.professionalSummary || details.educationAndCertificate || details.specializations || details.trainerCv; else noBioTemplate">
        <li>
          <span class="title">
            {{"profile.professionalSummary"|translate}}
          </span>
          <div class="desc">
            <p>
              {{details.professionalSummary}}
            </p>
          </div>
        </li>
        <li>
          <span class="title">
            {{"profile.educationAndCertificate"|translate}}
          </span>
          <div class="desc">
            <p>
              {{details.educationAndCertificate}}
            </p>
          </div>
        </li>
        <li>
          <span class="title">
            {{"profile.specializations"|translate}}
          </span>
          <div class="desc">
            <p>
              {{details.specializations}}
            </p>
          </div>
        </li>
        <li *ngIf="details.trainerCv">
          <span class="title">
            {{"profile.trainerCv"|translate}}
          </span>
          <div class="desc">
            <app-files-viewer [viewFile]="true" [enableDownload]="false" (viewFileEvent)="openSidenav($event)"
              [files]="[details.trainerCv]"></app-files-viewer>
          </div>
        </li>
      </ul>
      <ng-template #noBioTemplate>
        <app-empty-state [img]='"./assets/images/illustration_bio.png"' [desc]='"profile.noBioMessage"|translate'>
        </app-empty-state>
      </ng-template>
    </div>
  </div>
</ng-container>

<app-sidenav-over *ngIf="openSideNav" [open]="openSideNav" (closeDetails)="closeSidenav($event)">
  <app-attachment-details (closeDetails)="closeSidenav($event)"
    [attachment]="selectedAttachment"></app-attachment-details>
</app-sidenav-over>
