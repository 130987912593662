<div class="app-mian-header  {{customClass}}">
  <div class="header-inner">
    <div class="menu-toggle" (click)="toggelSidebar()">
      <img src="assets/images/icons/minimize-sidebar-icon.svg" alt="">
    </div>
    <ng-container *ngIf="!forExtract; else forlayout">
      <div class="logo-mobile">
        <img src="./assets/images/logo/smallLogo.svg">
      </div>
    </ng-container>
    <ng-template #forlayout>
      <div class="logo-forextract">
        <img src="./assets/images/logo/histo-app-logo.png">
      </div>
    </ng-template>

    <div class="header-navigation" *ngIf="hasPermission('Trainee')">
      <ul>
        <li>
          <a [routerLink]="['/admin/courses']" routerLinkActive="active">{{"sideBar.courses"|translate}}</a>
        </li>
        <li>
          <a [routerLink]="['/admin/cases/slideCases']" routerLinkActive="active">{{"sideBar.slides"|translate}}</a>
        </li>
        <li>
          <a [routerLink]="['/admin/discussion-forum']"
            routerLinkActive="active">{{"discussionForum.discussionForum"|translate}}</a>
        </li>
      </ul>
    </div>
    <div class="header-part-right">
      <div class="search" *ngIf="hasPermission('Trainee') && showSearch">
        <input [(ngModel)]="selectedText" maxlength="100" (keyup.enter)="navigateToSearchReasult()"
          [placeholder]="'courses.search'|translate">
        <button type="button" mat-icon-button matSuffix (click)="navigateToSearchReasult()">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path id="ic_search"
              d="M-3.413-18.75a5.81,5.81,0,0,1,4.2,1.737,5.924,5.924,0,0,1,1.716,4.225,6.015,6.015,0,0,1-.365,2.1A5.483,5.483,0,0,1,1.091-8.927l.257.257h.729L6.625-4.123,5.252-2.75.705-7.3v-.729L.448-8.284A5.483,5.483,0,0,1-1.311-7.233a6.015,6.015,0,0,1-2.1.365A5.924,5.924,0,0,1-7.638-8.584a5.81,5.81,0,0,1-1.737-4.2,5.884,5.884,0,0,1,1.737-4.225A5.884,5.884,0,0,1-3.413-18.75Zm0,1.845a4.1,4.1,0,0,0-2.917,1.2,3.955,3.955,0,0,0-1.158,2.917A3.955,3.955,0,0,0-6.329-9.871,3.955,3.955,0,0,0-3.413-8.712,3.955,3.955,0,0,0-.5-9.871,3.955,3.955,0,0,0,.663-12.788,3.955,3.955,0,0,0-.5-15.7,4.1,4.1,0,0,0-3.413-16.906Z"
              transform="translate(9.375 18.75)" fill="#7d4393" opacity="0.85" />
          </svg>
        </button>
      </div>
      <div class="actions_group">
        <app-accessibility-menu class="d-flex"></app-accessibility-menu>
        <a class="btn change-language" (click)="changeLanguage()">
          <!-- <img src="./assets/images/icons/language-icon.svg" alt=""> -->
          {{ 'general.language' | translate }}
        </a>
      </div>
      <div class="elementor-widget-container" *ngIf="hasPermission('Trainer') || hasPermission('Trainee')">
        <div class="notification-btn dropdown-container" appDropDown>
          <span class="dropdown-toggler not-after">
            <span class="notification-count">{{ nmessagesList.length }}</span>
            <img src="./assets/images/icons/chat.svg">
          </span>
          <div class="notification-panel dropdown-inner-menu">
            <div class="dropdown-menu-header">
              <h3>Messages</h3>
            </div>

            <div *ngIf='messagesLoading' class="dataLoading">
              <mat-spinner class="m-auto" diameter="50"></mat-spinner>
            </div>
            <div [hidden]='messagesLoading'>
              <div class="no-data text-center px-2 py-5" *ngIf="!nmessagesList.length">
                You have no Messages
              </div>
              <div class="notification-list" *ngIf="nmessagesList.length">
                <div class="border-0" *ngFor="let message of nmessagesList">
                  <a class="message_user_item"
                    [routerLink]="['/admin/cases/details', message.caseID, message.caseIdType]"
                    [queryParams]="{ chatUserId: message.fromId }" *ngIf="!message.courseId">
                    <div class="inner_message_item">
                      <div class="img-circle">
                        <div class="image m-0">
                          <img *ngIf="message.image" [src]="message.image" (error)='message.image = ""'>
                          <span *ngIf="!message.image">{{ message.name | shortName }}</span>
                        </div>
                      </div>
                      <div class="name">
                        <div class="top_head_message">
                          <h5>{{message.name}}</h5>
                          <span class="date_time">
                            {{message.messageTime | timeDiff}}
                          </span>
                        </div>
                        <div>
                          <span class="mesage_content">{{message.message | truncateText:80}}</span>
                        </div>
                      </div>
                    </div>
                  </a>
                  <a class="message_user_item" [routerLink]="['/admin/trainingCourses/details', message.courseId]"
                    [queryParams]="{ chatUserId: message.fromId,isGroupChat:message.isGroupChat }"
                    *ngIf="message.courseId && hasPermission('Trainer')">
                    <div class="inner_message_item">
                      <div class="img-circle">
                        <div class="image m-0">
                          <img *ngIf="message.image" [src]="message.image" (error)='message.image = ""'>
                          <span *ngIf="!message.image">{{ message.name | shortName }}</span>
                        </div>
                      </div>
                      <div class="name">
                        <div class="top_head_message">
                          <h5>{{message.name}}</h5>
                          <span class="date_time">
                            {{message.messageTime | timeDiff}}
                          </span>
                        </div>
                        <p *ngIf="!message.isGroupChat">{{message.courseName}}</p>
                        <div>
                          <span class="mesage_content">{{message.message | truncateText:80}}</span>
                        </div>
                      </div>
                    </div>
                  </a>
                  <a class="message_user_item" [class.disabled]="message.courseStatus !== courseStatus.INPROGRESS"
                    [routerLink]="['/admin/courses', message.courseId]"
                    [queryParams]="{ chatUserId: message.fromId,isGroupChat:message.isGroupChat }"
                    *ngIf="message.courseId && hasPermission('Trainee')">
                    <div class="inner_message_item">
                      <div class="img-circle">
                        <div class="image m-0">
                          <img *ngIf="message.image" [src]="message.image" (error)='message.image = ""'>
                          <span *ngIf="!message.image">{{ message.name | shortName }}</span>
                        </div>
                      </div>
                      <div class="name">
                        <div class="top_head_message">
                          <h5>{{message.name}}</h5>
                          <span class="date_time">
                            {{message.messageTime | timeDiff}}
                          </span>
                        </div>
                        <p *ngIf="!message.isGroupChat">{{message.courseName}}</p>
                        <div>
                          <span class="mesage_content">{{message.message | truncateText:80}}</span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="elementor-widget-container">
        <div class="notification-btn dropdown-container" appDropDown>
          <span (click)="openNotificationPanel()" class="dropdown-toggler not-after">
            <span class="notification-count">{{ notificationsResult.unSeenNotificationsCount }}</span>
            <!-- <img src="./assets/images/icons/notifications-icon.svg"> -->
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21"
              [ngClass]="{swing: notificationsResult.unSeenNotificationsCount}">
              <path id="notifications-icon"
                d="M269.347,364.745l-1.974-1.955v-3.331a7.314,7.314,0,0,0-6.575-7.241v-1.449h-1.462v1.449a7.374,7.374,0,0,0-6.576,7.241v3.331l-1.973,1.955a.66.66,0,0,0-.219.507v2.172a.687.687,0,0,0,.731.725h5.115a3.653,3.653,0,0,0,7.306,0h5.115a.684.684,0,0,0,.73-.634.7.7,0,0,0,0-.091v-2.172A.656.656,0,0,0,269.347,364.745Zm-9.28,5.576a2.182,2.182,0,0,1-2.192-2.172h4.384a2.182,2.182,0,0,1-2.192,2.172ZM268.1,366.7H252.03v-1.158L254,363.586a.657.657,0,0,0,.219-.506v-3.621a5.845,5.845,0,0,1,11.69,0v3.621a.657.657,0,0,0,.219.506l1.973,1.956Z"
                transform="translate(-250.566 -350.769)" fill="gray" />
            </svg>

          </span>
          <div class="notification-panel dropdown-inner-menu">
            <div class="dropdown-menu-header">
              <h3>{{ 'header.Notification' | translate }}</h3>
              <a *ngIf="hasPermission('Trainee')" [routerLink]="['/admin/notification-preference']" mat-button
                color="primary" class="btn_link_with_icon p-0 mx-2">
                <img src="./assets/images/icons/Notification-Preferences.svg">
                <span class="ml-1">
                  {{ 'general.preferences' | translate }}
                </span>
              </a>
            </div>

            <div>
              <div class="no-data text-center px-2 py-5" *ngIf="!notificationsList.length">
                {{ 'header.You have no notifications' | translate }}!
              </div>
              <div class="notification-list" *ngIf="notificationsList.length" infiniteScroll [scrollWindow]="false"
                [infiniteScrollDistance]="2" [infiniteScrollThrottle]="pageCount" (scrolled)="getNextPage()">
                <div *ngFor="let notif of notificationsList" [ngClass]="notif.isSeen ? '':'new'">
                  <ng-container [ngSwitch]="notif.notificationType">
                    <a *ngSwitchCase="'LAB_UPDATE'" (click)="getUnSeeNotifications(notif,['/admin/labProfile'])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'CASE_UPDATE'"
                      (click)="getUnSeeNotifications(notif,['/admin/cases/details', notif.objectId])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'CASE_UPDATE_QUALITY'"
                      (click)="getUnSeeNotifications(notif,['/admin/cases/details', notif.objectId],{isForQuality: true })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'REF_CASE_UPDATE'"
                      (click)="getUnSeeNotifications(notif,['/admin/cases/details', notif.objectId,'RefCase'])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'REF_CASE_UPDATE_GROSSING'"
                      (click)="getUnSeeNotifications(notif,['/admin/cases/details', notif.objectId,'RefCase'],{isForGrossing: true })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'REF_CASE_UPDATE_QUALITY'"
                      (click)="getUnSeeNotifications(notif,['/admin/cases/details', notif.objectId,'RefCase'],{isForQuality: true })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'CASE_UPDATE_SECOND_OPINION'"
                      (click)="getUnSeeNotifications(notif,['/admin/cases/details', notif.objectId])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'CASE_UPDATE_SENT_BACK'"
                      (click)="getUnSeeNotifications(notif,['/admin/cases/details', notif.objectId],{requestId:notif.param3En})">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'CASE_UPDATE_PATHOLOGIST'"
                      (click)="getUnSeeNotifications(notif,['/admin/cases/details', notif.objectId],{userId:notif.param4, userType:notif.param5 })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'CASE_REF_UPDATE_PATHOLOGIST'"
                      (click)="getUnSeeNotifications(notif,['/admin/cases/details', notif.objectId,'RefCase'],{userId:notif.param4, userType:notif.param5 })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'CASE_UPDATE_ENDORSEMENT'"
                      (click)="getUnSeeNotifications(notif,['/admin/cases/details', notif.objectId],{userId:notif.param4, userType:notif.param5, IsEndorsement: true })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'REF_CASE_UPDATE_ENDORSEMENT'"
                      (click)="getUnSeeNotifications(notif,['/admin/cases/details', notif.objectId,'RefCase'],{userId:notif.param4, userType:notif.param5, IsEndorsement: true })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'CASE_UPDATE_CANCELLED'">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'CASE_GROSSING'"
                      (click)="getUnSeeNotifications(notif,['/admin/cases/details', notif.objectId],{isForGrossing: true })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>

                    <!-- Notifications SLA Histo -->
                    <a *ngSwitchCase="'REJECTED_SLA'"
                      (click)="getUnSeeNotifications(notif,['/admin/sla/'+getEntity(notif.param2).value + '/preview-sla/' + notif.param4])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'ADDED_SLA'"
                      (click)="getUnSeeNotifications(notif,['/admin/sla/'+getEntity(notif.param2).value + '/preview-sla/' + notif.param3 +'/true'])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'TERMINTED_SLA'"
                      (click)="getUnSeeNotifications(notif,['/admin/sla/'+getEntity(notif.param2).value + '/preview-sla/' + notif.objectId +'/true'])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'UPDATED_SLA'"
                      (click)="getUnSeeNotifications(notif,['/admin/sla/'+getEntity(notif.param2).value + '/preview-sla/' + notif.param3 +'/true'])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>

                    <!-- Notifications SLA With Entities -->
                    <a *ngSwitchCase="'REJECTED_SLA_CONTRACT'"
                      (click)="getUnSeeNotifications(notif,['/admin/sla/'+getEntityType(notif.param2,notif.notificationType) + '/preview-sla/' + notif.param4])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'ADDED_SLA_CONTRACT'"
                      (click)="getUnSeeNotifications(notif,['/admin/sla/'+getEntityType(notif.param1,notif.notificationType) + '/preview-sla/' + notif.objectId])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'TERMINATED_SLA_CONTRACT'"
                      (click)="getUnSeeNotifications(notif,['/admin/sla/'+getEntityType(notif.param2,notif.notificationType) + '/preview-sla/' + notif.objectId])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'UPDATED_SLA_CONTRACT'"
                      (click)="getUnSeeNotifications(notif,['/admin/sla/'+getEntityType(notif.param1,notif.notificationType) + '/preview-sla/' + notif.param3])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>

                    <!-- Notifications Pathologist Request -->
                    <a *ngSwitchCase="'REGISTERATION'"
                      (click)="getUnSeeNotifications(notif,['/admin/Pathologists/Requests'])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>

                    <!-- Notifications invoice -->
                    <!-- <a *ngSwitchCase="'REGISTERATION'"
                      (click)="getUnSeeNotifications(notif,['/admin/Pathologists/Requests'])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a> -->

                    <!-- Notifications Requests -->
                    <a *ngSwitchCase="'REQUEST_UPDATED'"
                      (click)="getUnSeeNotifications(notif,['/admin/requests/details/'+notif.objectId])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'ACCEPT_REQUEST'"
                      (click)="getUnSeeNotifications(notif,['/admin/requests/details/'+notif.objectId])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>

                    <a *ngSwitchCase="'WAITING_PICKUP'"
                      (click)="getUnSeeNotifications(notif,['/admin/requests/details/'+notif.objectId],{model: deliverPickup.PickupAndDelivery, requestType : requestType.PICKUP })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'WAITING_PICKUP_CONTAINER'"
                      (click)="getUnSeeNotifications(notif,['/admin/requests/details/'+notif.objectId],{model: deliverPickup.PickupAndDelivery, requestType : requestType.PICKUP })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'WAITING_DELIVERY'"
                      (click)="getUnSeeNotifications(notif,['/admin/requests/details/'+notif.objectId],{model: deliverPickup.PickupAndDelivery, requestType : requestType.DELIVERY })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'WAITING_DELIVERY_CONTAINER'"
                      (click)="getUnSeeNotifications(notif,['/admin/requests/details/'+notif.objectId],{model: deliverPickup.PickupAndDelivery, requestType : requestType.DELIVERY })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'WAITING_CONTAINER_DELIVERY'"
                      (click)="getUnSeeNotifications(notif,['/admin/requests/details/'+notif.objectId],{model: deliverPickup.PickupAndDelivery, requestType : requestType.DELIVERY })">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'REMOVE_PICKUP_REPRESENTATIVE'">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'REMOVE_PICKUP_CONTAINER_REPRESENTATIVE'">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'REMOVE_DELIVERY_REPRESENTATIVE'">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'REMOVE_DELIVERY_CONTAINER_REPRESENTATIVE'">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>

                    <!-- Notifications Payment -->
                    <a *ngSwitchCase="'MARK_PAID'"
                      (click)="getUnSeeNotifications(notif,['/admin/payments/'+ (hasPermission('VLAdmin') || hasPermission('VLAccountant')?'VLTransactions':'incoming-outgoing')],{id:notif.objectId})">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'MARK_UNPAID'"
                      (click)="getUnSeeNotifications(notif,['/admin/payments/'+ (hasPermission('VLAdmin') || hasPermission('VLAccountant')?'VLTransactions':'incoming-outgoing')],{id:notif.objectId})">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'CHECKOUT'"
                      (click)="getUnSeeNotifications(notif,['/admin/payments/'+ (hasPermission('VLAdmin') || hasPermission('VLAccountant')?'VLTransactions':'incoming-outgoing')],{id:notif.objectId})">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'MARK_PAID_HISTO'"
                      (click)="getUnSeeNotifications(notif,['/admin/payments/HistoFeesTransactions'],{id:notif.objectId})">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'MARK_UNPAID_HISTO'"
                      (click)="getUnSeeNotifications(notif,['/admin/payments/HistoFeesTransactions'],{id:notif.objectId})">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                    <a *ngSwitchCase="'CHECKOUT_HISTO'"
                      (click)="getUnSeeNotifications(notif,['/admin/payments/HistoFeesTransactions'],{id:notif.objectId})">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>

                    <!-- Notifications Courses -->
                    <a *ngSwitchCase="'SCORE_ASSIGNMENT'"
                      (click)="getUnSeeNotifications(notif,['/admin/courses',notif.objectId],{nextModuleLessonId:notif.param2En})">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>

                    <a *ngSwitchCase="'SESSION_STARTED'"
                      (click)="getUnSeeNotifications(notif,['/admin/courses',notif.objectId])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>

                    <a *ngSwitchCase="'IDENYIY_VERIFICATION_APPROVED'"
                      (click)="getUnSeeNotifications(notif,['/admin/courses',notif.objectId])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>

                    <a *ngSwitchCase="'IDENTITY_VERIFICATION_DENIED'"
                      (click)="getUnSeeNotifications(notif,['/admin/courses',notif.objectId])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>

                    <a *ngSwitchCase="'WITHDRAW_COURSE'"
                      (click)="getUnSeeNotifications(notif,['/admin/trainingCourses/details',notif.objectId])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>

                    <a *ngSwitchCase="'RECIVED_SURVEY'"
                      (click)="getUnSeeNotifications(notif,['survey',notif.objectId])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>

                    <a *ngSwitchCase="'IDENTITY_VERIFICATION_NEED_REVIEW'"
                      (click)="getUnSeeNotifications(notif,['/admin/exam-proctoring'])">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>

                    <!-- Notifications Payment -->
                    <a *ngSwitchCase="'INSUFFICIENT_CREDIT_WINSTON_OUTPUT'">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>

                    <!-- Notifications Certificates -->
                    <a *ngSwitchCase="'CERTIFICATION_UPDATED'">
                      <p class="title">{{notif.text}}</p>
                      <span class="date">{{notif.date | date:'d MMM, y (h:mm a)'}}</span>
                    </a>
                  </ng-container>
                </div>
              </div>
              <div *ngIf='notifLoading' class="dataLoading py-1">
                <mat-spinner class="m-auto" diameter="50"></mat-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="elementor-widget-container">
        <div *ngIf="currentUser" class="userAccount dropdown-container m-0" id="userAccountDrop" appDropDown>
          <div class="dropdown-toggler d-flex align-items-center">
            <div class="profile-image">
              <img *ngIf="currentUser.ImagePath" [src]="currentUser.ImagePath" (error)="currentUser.ImagePath = ''" />
              <span *ngIf="!currentUser.ImagePath" [innerHTML]="currentUser.FullName | shortName"></span>
            </div>
            <div class="user-name">
              <span class="mr-2"> {{ 'header.Hello' | translate }}, {{currentUser.FullName|titlecase}}</span>
            </div>
          </div>

          <div class="custom-dropdown-menu dropdown-inner-menu">
            <ul>
              <!-- *ngIf="!hasPermission('Trainer')" -->
              <li>
                <a [routerLink]="['/admin/myprofile']">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <path id="ic_profile"
                      d="M102.6-849.1a8.041,8.041,0,0,1,2.047-1.1,6.827,6.827,0,0,1,2.357-.4,6.827,6.827,0,0,1,2.357.4,8.041,8.041,0,0,1,2.047,1.1,5.809,5.809,0,0,0,1.089-1.749,5.682,5.682,0,0,0,.4-2.151,5.682,5.682,0,0,0-1.722-4.172A5.682,5.682,0,0,0,107-858.895a5.682,5.682,0,0,0-4.172,1.722A5.682,5.682,0,0,0,101.105-853a5.682,5.682,0,0,0,.4,2.151A5.809,5.809,0,0,0,102.6-849.1Zm4.4-3.347a2.314,2.314,0,0,1-1.7-.693,2.313,2.313,0,0,1-.693-1.7,2.314,2.314,0,0,1,.693-1.7,2.313,2.313,0,0,1,1.7-.693,2.314,2.314,0,0,1,1.7.693,2.313,2.313,0,0,1,.693,1.7,2.314,2.314,0,0,1-.693,1.7A2.313,2.313,0,0,1,107-852.447ZM107-846a6.861,6.861,0,0,1-2.736-.548,7.028,7.028,0,0,1-2.223-1.494,7.028,7.028,0,0,1-1.494-2.222A6.861,6.861,0,0,1,100-853a6.861,6.861,0,0,1,.548-2.736,7.028,7.028,0,0,1,1.494-2.222,7.028,7.028,0,0,1,2.223-1.494A6.861,6.861,0,0,1,107-860a6.861,6.861,0,0,1,2.736.548,7.028,7.028,0,0,1,2.223,1.494,7.028,7.028,0,0,1,1.494,2.222A6.861,6.861,0,0,1,114-853a6.861,6.861,0,0,1-.548,2.736,7.028,7.028,0,0,1-1.494,2.222,7.028,7.028,0,0,1-2.223,1.494A6.861,6.861,0,0,1,107-846Zm0-1.105a5.833,5.833,0,0,0,1.924-.321,5.461,5.461,0,0,0,1.644-.9,5.6,5.6,0,0,0-1.623-.866A5.955,5.955,0,0,0,107-849.5a6.052,6.052,0,0,0-1.948.307,5.343,5.343,0,0,0-1.62.87,5.461,5.461,0,0,0,1.644.9A5.833,5.833,0,0,0,107-847.105Zm0-6.447a1.248,1.248,0,0,0,.92-.37,1.248,1.248,0,0,0,.37-.92,1.248,1.248,0,0,0-.37-.92,1.248,1.248,0,0,0-.92-.37,1.248,1.248,0,0,0-.92.37,1.248,1.248,0,0,0-.37.92,1.248,1.248,0,0,0,.37.92A1.248,1.248,0,0,0,107-853.553ZM107-854.842ZM107-848.3Z"
                      transform="translate(-100 860)" fill="#5d6d7e" />
                  </svg>
                  {{ 'header.Edit-Profile' | translate }}
                </a>
              </li>
              <li *ngIf="hasPermission('Trainee')">
                <a [routerLink]="['/admin/mycertificates']">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <path id="ic_my_certificates"
                      d="M19041.012-17685.012l-1.012-.215-1.016.215a.407.407,0,0,1-.35-.088.447.447,0,0,1-.154-.342v-1.924a2.858,2.858,0,0,1-1.2-2.344,2.782,2.782,0,0,1,2.719-2.83,2.781,2.781,0,0,1,2.717,2.832,2.863,2.863,0,0,1-1.193,2.342v1.924a.455.455,0,0,1-.156.342.43.43,0,0,1-.268.1A.347.347,0,0,1,19041.012-17685.012Zm-.926-1.1.59.125v-.986a2.588,2.588,0,0,1-.676.092,2.591,2.591,0,0,1-.678-.092v.986l.592-.125a.414.414,0,0,1,.086-.01A.446.446,0,0,1,19040.086-17686.107Zm-1.957-3.6a1.913,1.913,0,0,0,1.871,1.949,1.913,1.913,0,0,0,1.871-1.949,1.913,1.913,0,0,0-1.871-1.949A1.913,1.913,0,0,0,19038.129-17689.707Zm5.785,1.223a.433.433,0,0,1-.426-.441.432.432,0,0,1,.426-.437h1.633a.616.616,0,0,0,.605-.629v-7.5a.618.618,0,0,0-.605-.631h-11.1a.62.62,0,0,0-.607.631v7.5a.618.618,0,0,0,.607.629h1.635a.434.434,0,0,1,.426.441.432.432,0,0,1-.426.438h-1.635a1.485,1.485,0,0,1-1.453-1.508v-7.5a1.487,1.487,0,0,1,1.453-1.51h11.1a1.485,1.485,0,0,1,1.451,1.51v7.5a1.484,1.484,0,0,1-1.451,1.508Zm-7.754-5.07a.431.431,0,0,1-.422-.437.432.432,0,0,1,.422-.441h7.676a.431.431,0,0,1,.422.441.43.43,0,0,1-.422.438Zm1.854-2.111a.432.432,0,0,1-.424-.441.432.432,0,0,1,.424-.439h3.971a.431.431,0,0,1,.424.439.431.431,0,0,1-.424.441Z"
                      transform="translate(-19032.998 17699.002)" fill="#5d6d7e" />
                  </svg>
                  {{ 'header.myCertificates' | translate }}
                </a>
              </li>
              <li *ngIf="currentUser && !currentUser.ForTrainning">
                <a [routerLink]="['/admin/configuration/media-center']">
                  <svg width="14" height="17" viewBox="0 0 17 17">
                    <path d="M1 4v6h15v-6h-15zM15 9h-13v-4h13v4zM14 11v1h-11v-1h11zM2 13h13v1h-13v-1z" fill="#999999" />
                  </svg>{{ 'header.media_center' | translate }}
                </a>
              </li>
              <li hidden><a [routerLink]="['changepassword']">
                  <svg width="14" height="17" viewBox="0 0 14 17">
                    <path id="my-profile-icon"
                      d="M7,8.5a12.35,12.35,0,0,1,5,1c1.3.6,2,1.4,2,2.4v1.7H0V11.9A2.954,2.954,0,0,1,2,9.5,12.35,12.35,0,0,1,7,8.5ZM2.6,15.3H4.4V17H2.6ZM7,0A3.74,3.74,0,0,1,9.5,1a2.965,2.965,0,0,1,1,2.4,2.965,2.965,0,0,1-1,2.4A3.74,3.74,0,0,1,7,6.8a3.317,3.317,0,0,1-2.5-1,3.1,3.1,0,0,1-1-2.4A2.965,2.965,0,0,1,4.5,1,3.74,3.74,0,0,1,7,0ZM6.1,15.3H7.8V17H6.1Zm3.5,0h1.8V17H9.6Z"
                      fill="#999999" />
                  </svg>{{ 'auth.Update-password' | translate }}
                </a></li>
              <li>
                <a (click)="logout()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <path id="ic_log_out"
                      d="M141.522-806a1.486,1.486,0,0,1-1.08-.432,1.421,1.421,0,0,1-.442-1.056v-11.023a1.421,1.421,0,0,1,.442-1.056,1.486,1.486,0,0,1,1.08-.432h5.011a.618.618,0,0,1,.45.177.591.591,0,0,1,.181.44.591.591,0,0,1-.181.44.618.618,0,0,1-.45.177h-5.011a.25.25,0,0,0-.178.079.24.24,0,0,0-.081.174v11.023a.24.24,0,0,0,.081.174.25.25,0,0,0,.178.079h5.011a.618.618,0,0,1,.45.177.591.591,0,0,1,.181.44.591.591,0,0,1-.181.44.618.618,0,0,1-.45.177Zm10.364-6.382h-6.543a.618.618,0,0,1-.45-.177.591.591,0,0,1-.181-.44.591.591,0,0,1,.181-.44.618.618,0,0,1,.45-.177h6.543l-1.619-1.584a.576.576,0,0,1-.179-.417.6.6,0,0,1,.179-.438.613.613,0,0,1,.444-.2.611.611,0,0,1,.457.185l2.605,2.548A.707.707,0,0,1,154-813a.707.707,0,0,1-.228.521l-2.605,2.548a.607.607,0,0,1-.446.181.639.639,0,0,1-.454-.194.6.6,0,0,1-.176-.44.6.6,0,0,1,.189-.428l1.606-1.571Z"
                      transform="translate(-140 820)" fill="#5d6d7e" />
                  </svg>
                  {{ 'header.SignOut' | translate }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>