import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AttachmentsValueModel } from 'src/app/models/lookups/AttachmentsValueModel';
import { UserDetails } from 'src/app/models/users/users/UserDetails';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-trainer-details',
  templateUrl: './trainer-details.component.html'
})
export class TrainerDetailsComponent implements OnInit {
  @Input() trainerId: string;
  @Output() closeDetails = new EventEmitter<boolean>();
  dataLoading = false;
  details: UserDetails = new UserDetails();

  openSideNav: boolean;
  selectedAttachment: AttachmentsValueModel;

  constructor(
    private service: UsersService
  ) { }

  ngOnInit() {
    this.getDetails();
  }

  getDetails() {
    this.dataLoading = true;
    this.service.getTrainerBio(this.trainerId).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.details = data as UserDetails;
        }
      },
      error => {
        this.dataLoading = false;
      });
  }

  openSidenav(attachment: AttachmentsValueModel) {
    this.selectedAttachment = attachment;
    this.openSideNav = true;
  }

  closeSidenav(e) {
    this.openSideNav = false;
  }

}
