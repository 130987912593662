import { ModelsEnum } from 'src/app/models/users/users/enums/ModelsEnum';
import { Availability } from './availability';
import { Lookup } from '../../lookups/Lookup';
import { statusList } from '../../sla/enums/statusList';
import { AttachmentsValueModel } from '../../lookups/AttachmentsValueModel';

export class UserDetails {
  assignedCases: null;
  subSpeciality: Lookup[];
  reviwer: {
    reviewerId: number;
    reviewerName: string;
    reviewerRole: string;
    reviewerRolesName: string[];
    logo: string;
  };
  canSendScondOpinion: boolean;
  availability: Availability[];
  fullName: string;
  firstName: string;
  threshold: string;
  userName: string;
  lastName: string;
  memberSince: Date;
  creationDate: Date;
  deactivatedOn: Date;
  contactNumberFlag: string;
  contactNumberExtension: string;
  contactNumber: string;
  email: string;
  address: string;
  country: Lookup;
  city: Lookup;
  region: Lookup;
  id: number;
  userImage: string;
  labLogo: string;
  lab: any;
  userStatus: any;
  userType: ModelsEnum;
  roles: string[];
  rolesNames: string[];
  isVerfiedMobile: boolean;
  // Vl
  coverageAreas?: Lookup[];
  coverageAreasVLProfile?: Lookup[];
  virtualLab?: Lookup;
  scanningCenter?: Lookup;

  identityNumber: string;
  identificationType: Lookup;
  otherIdentityType: string;
  addedByRole: string;
  worksFor: any;
  slas: {
    slaId: number;
    entityType: string;
    entityImage: string;
    entityName: string;
    slaStatus: statusList;
    startDate: Date;
    endDate: Date;
  }[];
  acceptedBy: any;
  declinedBy: any;
  acceptenceStatus: any;
  rejectionReason: string;
  createdByUser: any;
  showSlaField: string;
  canShareToConCural: boolean;
  conCuralPassword: string;
  conCuralUserName: string;
  scfhs: string;
  certifcateLanguage: Lookup;
  identityImage: AttachmentsValueModel;
  professionalSummary: string;
  educationAndCertificate: string;
  specializations: string;
  trainerCv: AttachmentsValueModel[] = new Array();
}
