import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SurveyType } from 'src/app/models/survey/enums/SurveyType.enum';
import { SurveyService } from 'src/app/services/survey.service';
import { SwalService } from 'src/app/services/swal.service';
import { Lookup } from 'src/app/models/lookups/Lookup';
import { LookupsService } from 'src/app/services/lookups.service';
import { ConfirmationDialogComponent } from 'src/app/partial/confirmation-dialog/confirmation-dialog.component';

export interface DialogData {
  id: number;
  type: SurveyType;
}
@Component({
  selector: 'app-send-survey',
  templateUrl: './send-survey.component.html',
})
export class SendSurveyComponent implements OnInit {
  dataLoading = false;
  loading = false;
  surveyType = SurveyType;
  formGroup: FormGroup;
  coursesList: Lookup[] = [];
  expirationList = [
    {
      name: this.translate.instant('survey.neverExpires'),
      id: 0,
    },
  ];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<SendSurveyComponent>,
    private formBuilder: FormBuilder,
    private dialog:MatDialog,
    private service: SurveyService,
    private swalService: SwalService,
    private lookupsService: LookupsService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    for (let i = 1; i <= 30; i++) {
      this.expirationList.push({
        name:
          i +
          ' ' +
          (i == 1
            ? this.translate.instant('survey.day')
            : this.translate.instant('survey.days')),
        id: i,
      });
    }
    if (this.data.type == this.surveyType.COURSE) {
      this.getAllCourses();
    }
    this.buildForm();
  }
  getAllCourses() {
    this.dataLoading = true;
    this.lookupsService.getAllCourses().subscribe(
      (data) => {
        this.coursesList = data as [];
        this.dataLoading = false;
      },
      (err) => {
        this.dataLoading = false;
        console.error(err);
      }
    );
  }
  buildForm() {
    this.formGroup = this.formBuilder.group({
      courseId: [null],
      surveyExpiration: [null, [Validators.required]],
      sendForUsersNotReceived: [false],
    });
  }

  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  saveRecord() {
    if (this.formGroup.valid) {
      this.loading = true;
      let data = {
        surveyId: this.data.id,
        courseId: this.formGroup.controls.courseId.value,
        surveyExpiration: this.formGroup.controls.surveyExpiration.value == 0 ? null: this.formGroup.controls.surveyExpiration.value,
        sendForUsersNotReceived:this.formGroup.controls.sendForUsersNotReceived.value,
      };

      this.service.sendSurvey(data).subscribe(
        (response) => {
          this.loading = false;
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '600px',
            data: {
              img: 'assets/images/icons/done_illustration.svg',
              title: this.translate.instant('survey.surveySentSuccess'),
              btnTitle: 'Done',
              btnClass: 'larg-btn',
            },
          });
          this.dialogRef.close(true);
        },
        (err) => {
          this.loading = false;
          // this.handleErrors(err.detail);
        }
      );
    }
  }
}
