import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CoursesIntegrationService {
  refreshDetails = new BehaviorSubject<any>(false);

  constructor(private http: HttpClient) { }

  getAll(params: any): any {
    return this.http.get(`${environment.apiUrl}/CourseIntegration/FilterCourse`, {
      params,
    });
  }

  getCourseDetails(id: number) {
    return this.http.get(
      `${environment.apiUrl}/CourseIntegration/GetCourseDetails?CourseId=${id}`
    );
  }

  getTrainerBioAnonymous(id: string) {
    return this.http.get(
      `${environment.apiUrl}/UserProfile/GetTrainerBioAnonymous?TrainerId=${id}`
    );
  }

  viewContent(id: number) {
    return this.http.get(`${environment.apiUrl}/CourseIntegration/ViewContent?LessonId=${id}`);
  }

  getAllOpenContentLessons(params: any): any {
    return this.http.get(`${environment.apiUrl}/CourseIntegration/GetAllOpenContentLessons`, {
      params,
    });
  }

  getOpenContentDetails(id: number) {
    return this.http.get(`${environment.apiUrl}/CourseIntegration/GetOpenContentDetails?LessonId=${id}`);
  }

  rateContent(model: any): any {
    return this.http.post(`${environment.apiUrl}/CourseIntegration/RateContent`, model);
  }

  countDownloadLesson(model: any): any {
    return this.http.post(`${environment.apiUrl}/CourseIntegration/CountDownloadLesson`, model);
  }

}
