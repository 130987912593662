export enum CourseFilterStatusEnum {
  All = "All",
  NOTAVALIBALE = "NOTAVALIBALE",
  INPROGRESS = "INPROGRESS",
  COMPLETED = "COMPLETED",
  NOTSTARTED = "NOTSTARTED",
  EXPIRE = "EXPIRE",
  WITHDRAWING = "WITHDRAWING",
  DISENROLLED = "DISENROLLED"
}
