
<div class="d-flex" *ngIf="!details.videoUrl else viewVideoUrl">
  <div class="video-body" [ngClass]="{'w-100': !toggleTranslate}">

    <div class="video">
      <div class="video-player" #videoContainer>
        <video #videoPlayer id="myVideo" crossorigin="anonymous" (ended)="videoEnded()" (click)="togglePlay($event)" [hidden]="isFullScreen"
          (timeupdate)="updateProgress();syncSubtitles()" (loadedmetadata)="setDuration()">
          <track default kind="captions" [src]="trustedSubtitleUrl">
        </video>
        <div class="play-controls" [class.hide]="isPlaying">
          <span (click)="togglePlay($event)">
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="66" viewBox="0 0 60 66">
              <path id="play_video" data-name="play video"
                d="M18.978,1.455C12.974-1.958,8.107.838,8.107,7.7V58.3c0,6.864,4.867,9.657,10.871,6.247L63.6,39.18c6.005-3.414,6.005-8.946,0-12.36Z"
                transform="translate(-8.107)" fill="#fff" opacity="0.9" />
            </svg>
          </span>
        </div>
        <div class="custom-controls" [class.hide]="isPlaying">
          <div class="progress-bar-container" (click)="seekTo($event)">
            <div class="progress-bar" [style.width.%]="progress"></div>
          </div>
          <div class="bottom-controls">
            <div class="d-flex">
              <div class="control translate" (click)="togglePlay($event)">
                <svg *ngIf="isPlaying" xmlns="http://www.w3.org/2000/svg" width="16" height="20"
                  viewBox="0 0 16 20">
                  <path id="ic_pause"
                    d="M253.333-740a2.485,2.485,0,0,1-1.883-.839,2.853,2.853,0,0,1-.783-2.018v-14.286a2.853,2.853,0,0,1,.783-2.018,2.485,2.485,0,0,1,1.883-.839,2.485,2.485,0,0,1,1.883.839,2.853,2.853,0,0,1,.783,2.018v14.286a2.853,2.853,0,0,1-.783,2.018A2.485,2.485,0,0,1,253.333-740Zm-10.667,0a2.485,2.485,0,0,1-1.883-.839,2.853,2.853,0,0,1-.783-2.018v-14.286a2.853,2.853,0,0,1,.783-2.018,2.485,2.485,0,0,1,1.883-.839,2.485,2.485,0,0,1,1.883.839,2.853,2.853,0,0,1,.783,2.018v14.286a2.853,2.853,0,0,1-.783,2.018A2.485,2.485,0,0,1,242.667-740Z"
                    transform="translate(-240 760)" fill="#fff" />
                </svg>
                <svg *ngIf="!isPlaying" xmlns="http://www.w3.org/2000/svg" width="16" height="20"
                  viewBox="0 0 16 20">
                  <path id="ic_play"
                    d="M320-708.619v-16.761a1.552,1.552,0,0,1,.474-1.154A1.529,1.529,0,0,1,321.58-727a1.542,1.542,0,0,1,.415.061,1.626,1.626,0,0,1,.415.182l12.879,8.381a1.555,1.555,0,0,1,.533.607A1.732,1.732,0,0,1,336-717a1.732,1.732,0,0,1-.178.769,1.555,1.555,0,0,1-.533.607l-12.879,8.381a1.626,1.626,0,0,1-.415.182,1.542,1.542,0,0,1-.415.061,1.529,1.529,0,0,1-1.106-.466A1.552,1.552,0,0,1,320-708.619Z"
                    transform="translate(-320 727)" fill="#fff" />
                </svg>
              </div>
              <div class="volume-control">
                <button class="volume-icon" (click)="toggleVolumeSlider()">
                  <span *ngIf="isMuted || volume === 0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14.348" viewBox="0 0 10 14.348">
                      <path id="ic_speaker"
                        d="M124.444-800.215h-3.333a1.088,1.088,0,0,1-.792-.312A1.039,1.039,0,0,1,120-801.3v-4.344a1.039,1.039,0,0,1,.319-.774,1.088,1.088,0,0,1,.792-.312h3.333l3.667-3.584a1.03,1.03,0,0,1,1.208-.231,1.022,1.022,0,0,1,.681,1.018v12.109a1.022,1.022,0,0,1-.681,1.018,1.03,1.03,0,0,1-1.208-.231Z"
                        transform="translate(-120 810.647)" fill="#fff" />
                    </svg>
                  </span>
                  <span *ngIf="!isMuted && volume > 0 && volume < 0.5">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14.348" viewBox="0 0 15 14.348">
                      <path id="ic_speaker"
                        d="M124.444-800.215h-3.333a1.088,1.088,0,0,1-.792-.312A1.039,1.039,0,0,1,120-801.3v-4.344a1.039,1.039,0,0,1,.319-.774,1.088,1.088,0,0,1,.792-.312h3.333l3.667-3.584a1.03,1.03,0,0,1,1.208-.231,1.022,1.022,0,0,1,.681,1.018v12.109a1.022,1.022,0,0,1-.681,1.018,1.03,1.03,0,0,1-1.208-.231ZM135-803.473a4.625,4.625,0,0,1-.528,2.158,4.866,4.866,0,0,1-1.389,1.67.558.558,0,0,1-.569.014.5.5,0,0,1-.292-.475v-6.787a.5.5,0,0,1,.292-.475.558.558,0,0,1,.569.014,5.1,5.1,0,0,1,1.389,1.71A4.706,4.706,0,0,1,135-803.473Z"
                        transform="translate(-120 810.647)" fill="#fff" />
                    </svg>
                  </span>
                  <span *ngIf="!isMuted && volume >= 0.5">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                      <path id="ic_speaker"
                        d="M137.778-803.5a7.332,7.332,0,0,0-1.222-4.113,7.532,7.532,0,0,0-3.278-2.783,1.225,1.225,0,0,1-.611-.584,1.008,1.008,0,0,1-.056-.8,1.083,1.083,0,0,1,.6-.624,1.055,1.055,0,0,1,.875,0,9.729,9.729,0,0,1,4.306,3.57A9.371,9.371,0,0,1,140-803.5a9.371,9.371,0,0,1-1.611,5.335,9.73,9.73,0,0,1-4.306,3.57,1.055,1.055,0,0,1-.875,0,1.083,1.083,0,0,1-.6-.624,1.008,1.008,0,0,1,.056-.8,1.225,1.225,0,0,1,.611-.584,7.532,7.532,0,0,0,3.278-2.783A7.332,7.332,0,0,0,137.778-803.5Zm-13.333,3.285h-3.333a1.088,1.088,0,0,1-.792-.312A1.039,1.039,0,0,1,120-801.3v-4.344a1.039,1.039,0,0,1,.319-.774,1.088,1.088,0,0,1,.792-.312h3.333l3.667-3.584a1.03,1.03,0,0,1,1.208-.231,1.022,1.022,0,0,1,.681,1.018v12.109a1.022,1.022,0,0,1-.681,1.018,1.03,1.03,0,0,1-1.208-.231ZM135-803.473a4.625,4.625,0,0,1-.528,2.158,4.866,4.866,0,0,1-1.389,1.67.558.558,0,0,1-.569.014.5.5,0,0,1-.292-.475v-6.787a.5.5,0,0,1,.292-.475.558.558,0,0,1,.569.014,5.1,5.1,0,0,1,1.389,1.71A4.706,4.706,0,0,1,135-803.473Z"
                        transform="translate(-120 812.5)" fill="#fff" />
                    </svg>
                  </span>
                </button>
                <div class="volume-slider-container" *ngIf="showVolumeSlider">
                  <input type="range" min="0" max="1" step="0.01" [(ngModel)]="volume"
                    (input)="changeVolume($event)" />
                </div>
              </div>
              <div class="time-info">
                <span>{{ formatTime(currentTime) }} / {{ formatTime(duration) }}</span>
              </div>
            </div>
            <div class="d-flex">
              <div class="control translate" (click)="openTranslate()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20">
                  <path id="ic_transcript"
                    d="M165-864h6a.968.968,0,0,0,.712-.287A.968.968,0,0,0,172-865a.968.968,0,0,0-.288-.713A.968.968,0,0,0,171-866h-6a.968.968,0,0,0-.712.287A.968.968,0,0,0,164-865a.968.968,0,0,0,.288.713A.968.968,0,0,0,165-864Zm0-4h6a.968.968,0,0,0,.712-.287A.968.968,0,0,0,172-869a.968.968,0,0,0-.288-.713A.968.968,0,0,0,171-870h-6a.968.968,0,0,0-.712.287A.968.968,0,0,0,164-869a.968.968,0,0,0,.288.713A.968.968,0,0,0,165-868Zm-3,8a1.926,1.926,0,0,1-1.413-.588A1.926,1.926,0,0,1,160-862v-16a1.926,1.926,0,0,1,.587-1.412A1.926,1.926,0,0,1,162-880h7.175a1.974,1.974,0,0,1,.762.15,1.943,1.943,0,0,1,.637.425l4.85,4.85a1.944,1.944,0,0,1,.425.638,1.975,1.975,0,0,1,.15.763V-862a1.926,1.926,0,0,1-.587,1.412A1.926,1.926,0,0,1,174-860Zm7-14a.968.968,0,0,0,.288.713A.968.968,0,0,0,170-873h4l-5-5Z"
                    transform="translate(-160 880)" fill="#fff" />
                </svg>
              </div>

              <div class="control download" *ngIf="details.canDownloadIt || isPreviewContent" (click)="downloadVideo()">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                  <path id="Path_94551" data-name="Path 94551" d="M170-785.531a1.384,1.384,0,0,1-.469-.078,1.1,1.1,0,0,1-.406-.266l-4.5-4.5a1.145,1.145,0,0,1-.359-.875,1.318,1.318,0,0,1,.359-.875,1.275,1.275,0,0,1,.891-.391,1.163,1.163,0,0,1,.891.359l2.344,2.344v-8.937a1.209,1.209,0,0,1,.359-.891A1.21,1.21,0,0,1,170-800a1.21,1.21,0,0,1,.891.359,1.209,1.209,0,0,1,.359.891v8.938l2.344-2.344a1.163,1.163,0,0,1,.891-.359,1.275,1.275,0,0,1,.891.391,1.318,1.318,0,0,1,.359.875,1.145,1.145,0,0,1-.359.875l-4.5,4.5a1.1,1.1,0,0,1-.406.266A1.384,1.384,0,0,1,170-785.531ZM162.5-780a2.407,2.407,0,0,1-1.766-.734A2.407,2.407,0,0,1,160-782.5V-785a1.209,1.209,0,0,1,.359-.891,1.21,1.21,0,0,1,.891-.359,1.21,1.21,0,0,1,.891.359,1.209,1.209,0,0,1,.359.891v2.5h15V-785a1.209,1.209,0,0,1,.359-.891,1.21,1.21,0,0,1,.891-.359,1.21,1.21,0,0,1,.891.359A1.209,1.209,0,0,1,180-785v2.5a2.407,2.407,0,0,1-.734,1.766A2.407,2.407,0,0,1,177.5-780Z" transform="translate(-160 800)" fill="#fff"/>
                </svg>
              </div>
              <div class="control fullscreen" (click)="toggleFullScreen()">
                <svg *ngIf="!isFullScreen" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                  viewBox="0 0 20 20">
                  <path id="ic_full_screen"
                    d="M122.222-822.222h2.222a1.075,1.075,0,0,1,.792.319,1.075,1.075,0,0,1,.319.792,1.075,1.075,0,0,1-.319.792,1.075,1.075,0,0,1-.792.319h-3.333a1.075,1.075,0,0,1-.792-.319,1.075,1.075,0,0,1-.319-.792v-3.333a1.075,1.075,0,0,1,.319-.792,1.075,1.075,0,0,1,.792-.319,1.075,1.075,0,0,1,.792.319,1.075,1.075,0,0,1,.319.792Zm15.556,0v-2.222a1.075,1.075,0,0,1,.319-.792,1.075,1.075,0,0,1,.792-.319,1.075,1.075,0,0,1,.792.319,1.075,1.075,0,0,1,.319.792v3.333a1.075,1.075,0,0,1-.319.792,1.075,1.075,0,0,1-.792.319h-3.333a1.075,1.075,0,0,1-.792-.319,1.075,1.075,0,0,1-.319-.792,1.075,1.075,0,0,1,.319-.792,1.075,1.075,0,0,1,.792-.319Zm-15.556-15.556v2.222a1.075,1.075,0,0,1-.319.792,1.075,1.075,0,0,1-.792.319,1.075,1.075,0,0,1-.792-.319,1.075,1.075,0,0,1-.319-.792v-3.333a1.075,1.075,0,0,1,.319-.792,1.075,1.075,0,0,1,.792-.319h3.333a1.075,1.075,0,0,1,.792.319,1.075,1.075,0,0,1,.319.792,1.075,1.075,0,0,1-.319.792,1.075,1.075,0,0,1-.792.319Zm15.556,0h-2.222a1.075,1.075,0,0,1-.792-.319,1.075,1.075,0,0,1-.319-.792,1.075,1.075,0,0,1,.319-.792,1.075,1.075,0,0,1,.792-.319h3.333a1.075,1.075,0,0,1,.792.319,1.075,1.075,0,0,1,.319.792v3.333a1.075,1.075,0,0,1-.319.792,1.075,1.075,0,0,1-.792.319,1.075,1.075,0,0,1-.792-.319,1.075,1.075,0,0,1-.319-.792Z"
                    transform="translate(-120 840)" fill="#fff" />
                </svg>
                <svg *ngIf="isFullScreen" xmlns="http://www.w3.org/2000/svg" version="1.1"
                  xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" x="0" y="0" viewBox="0 0 32 32"
                  style="enable-background:new 0 0 512 512" xml:space="preserve" class="">
                  <g>
                    <path
                      d="M11.144.33a1.896 1.896 0 0 0-1.852 1.906v7.388H1.95c-.554-.007-1.093.229-1.427.601s-.479.833-.479 1.28.145.908.479 1.28.873.608 1.427.601h9.223a1.896 1.896 0 0 0 1.881-1.881V2.236A1.897 1.897 0 0 0 11.144.33zm9.653-.023a1.897 1.897 0 0 0-1.852 1.91v9.219c0 1.027.854 1.881 1.881 1.881h9.267c.554.008 1.093-.229 1.427-.602.335-.371.48-.832.48-1.279s-.145-.908-.479-1.281c-.334-.371-.873-.607-1.427-.6h-7.386V2.217a1.896 1.896 0 0 0-1.911-1.91zm.03 18.306c-1.027 0-1.88.854-1.881 1.881v9.267c-.007.554.229 1.093.601 1.427s.833.479 1.28.479.908-.146 1.28-.479.608-.873.601-1.427v-7.386h7.34c.554.008 1.093-.229 1.427-.601s.479-.833.479-1.28-.146-.908-.479-1.28c-.334-.372-.873-.608-1.427-.601zm-18.921.069c-.554-.008-1.093.229-1.427.6-.334.372-.479.833-.479 1.281s.145.908.479 1.279c.334.373.873.609 1.427.602h7.386v7.341c-.007.554.229 1.093.601 1.427.372.335.833.48 1.28.48s.908-.146 1.28-.48.608-.873.601-1.427v-9.222a1.896 1.896 0 0 0-1.881-1.881z"
                      fill="#fff" opacity="0.9" data-original="#fff" class=""></path>
                  </g>
                </svg>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="video-ended" *ngIf="isFinished">
        <div *ngIf="btnLoading" class="dataLoading">
          <mat-spinner class="m-auto" diameter="50"></mat-spinner>
        </div>
        <div class="finished-msg" *ngIf="this.activeLesson.isCompleted">
          <div class="icon">
            <img src="./assets/images/completed.svg" />
          </div>
          <div>
            <p class="success">{{"trainee.completed"|translate}}</p>
            <span>{{"trainee.upNext"|translate}}:</span>
            <strong>{{"trainee.imagingDevices"|translate}}</strong>
            <button *ngIf="!activeLesson.isLast" (click)="service.nextContent.next(true)">
              {{(isLastLesson ? 'trainee.nextModule' :'trainee.nextUnit')|translate}}
            </button>
            <button *ngIf="activeLesson.isLast && !activeLesson.isCompleted"
              (click)="service.nextContent.next(true)">
              {{"trainee.markAsCompleted"|translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="video-translate" *ngIf="toggleTranslate">
    <div class="transcript-content">
      <div class="transcript-head">
        <h4>{{'courses.transcript' | translate}}</h4>
        <button (click)="downloadFile()" class="btn downloadTranscript">
          {{'courses.DownloadTranscript' | translate}}
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20">
            <path id="ic_transcript."
              d="M18689.057-10110.285a3.587,3.587,0,0,1-1.129-.776,3.671,3.671,0,0,1-.76-1.156,3.578,3.578,0,0,1-.279-1.416,3.576,3.576,0,0,1,.279-1.417,3.66,3.66,0,0,1,.76-1.156,3.493,3.493,0,0,1,1.129-.776,3.364,3.364,0,0,1,1.385-.29,3.4,3.4,0,0,1,1.389.29,3.456,3.456,0,0,1,1.125.776,3.675,3.675,0,0,1,.766,1.156,3.681,3.681,0,0,1,.279,1.417,3.684,3.684,0,0,1-.279,1.416,3.686,3.686,0,0,1-.766,1.156,3.548,3.548,0,0,1-1.125.776,3.392,3.392,0,0,1-1.389.285A3.361,3.361,0,0,1,18689.057-10110.285Zm.213-3.605a.35.35,0,0,0-.1.257.351.351,0,0,0,.1.256l.926.942a.336.336,0,0,0,.246.111.341.341,0,0,0,.25-.111l.926-.942a.367.367,0,0,0,.1-.256.366.366,0,0,0-.1-.257.346.346,0,0,0-.25-.1.343.343,0,0,0-.25.1l-.316.328v-1.164a.377.377,0,0,0-.105-.261.344.344,0,0,0-.254-.1.354.354,0,0,0-.254.1.365.365,0,0,0-.1.261v1.164l-.32-.328a.336.336,0,0,0-.244-.1A.338.338,0,0,0,18689.27-10113.891Zm-2.9,2.073h0Zm-6.59,0a1.691,1.691,0,0,1-1.254-.532,1.753,1.753,0,0,1-.523-1.284v-14.548a1.76,1.76,0,0,1,.523-1.285,1.692,1.692,0,0,1,1.254-.533h6.377a1.643,1.643,0,0,1,.678.137,1.63,1.63,0,0,1,.566.384l4.311,4.411a1.781,1.781,0,0,1,.377.58,1.746,1.746,0,0,1,.135.691v5.994a4.32,4.32,0,0,0-1.779-.379,4.335,4.335,0,0,0-2.666.908h-5.332a.877.877,0,0,0-.635.26.906.906,0,0,0-.256.649.9.9,0,0,0,.256.648.84.84,0,0,0,.635.26h3.928a4.614,4.614,0,0,0-.371,1.822,4.655,4.655,0,0,0,.367,1.816Zm2.031-8.83a.9.9,0,0,0-.256.647.9.9,0,0,0,.256.648.84.84,0,0,0,.635.26h5.332a.85.85,0,0,0,.637-.26.888.888,0,0,0,.252-.648.892.892,0,0,0-.252-.647.848.848,0,0,0-.637-.261h-5.332A.837.837,0,0,0,18681.809-10120.647Zm4.191-3.9a.88.88,0,0,0,.254.648.847.847,0,0,0,.635.261h3.553l-4.441-4.543Z"
              transform="translate(-18678 10130)" fill="#7d4393" />
          </svg>
        </button>
      </div>
      <div class="transcript-search">
        <mat-form-field class="search-field" appearance="outline">
          <input matInput [(ngModel)]="searchQuery" (input)="onSearch()">
          <div matSuffix class="arrow-buttons">
            <span class="searchResults" *ngIf="searchResults && searchResults.length">
              {{currentIndex + 1}} / {{searchResults.length}}
            </span>
            <button mat-icon-button [disableRipple]="true" (click)="navigateResults('up')">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7">
                <path id="ic_arrow_previous"
                  d="M263-621.445l-4.2,4.152a1.027,1.027,0,0,1-.753.293,1.027,1.027,0,0,1-.753-.293,1,1,0,0,1-.3-.745,1,1,0,0,1,.3-.745l4.951-4.9A1.038,1.038,0,0,1,263-624a1.038,1.038,0,0,1,.753.319l4.951,4.9a1,1,0,0,1,.3.745,1,1,0,0,1-.3.745,1.027,1.027,0,0,1-.753.293,1.027,1.027,0,0,1-.753-.293Z"
                  transform="translate(-257 624)" fill="#5d6d7e" />
              </svg>
            </button>
            <button mat-icon-button [disableRipple]="true" (click)="navigateResults('down')">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7">
                <path id="ic_arrow_next"
                  d="M263-619.555l-4.2-4.152a1.027,1.027,0,0,0-.753-.293,1.027,1.027,0,0,0-.753.293,1,1,0,0,0-.3.745,1,1,0,0,0,.3.745l4.951,4.9A1.038,1.038,0,0,0,263-617a1.038,1.038,0,0,0,.753-.319l4.951-4.9a1,1,0,0,0,.3-.745,1,1,0,0,0-.3-.745,1.027,1.027,0,0,0-.753-.293,1.027,1.027,0,0,0-.753.293Z"
                  transform="translate(-257 624)" fill="#5d6d7e" />
              </svg>
            </button>
            <button mat-icon-button [disableRipple]="true" *ngIf="searchQuery" (click)="clearSearch()">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </mat-form-field>
      </div>
      <!-- Transcript Section -->
      <div #transcript class="transcript">
        <p *ngFor="let sub of parsedSubtitles; let i = index" [id]="'subtitle-' + i">
          <ng-container *ngIf="sub.text !== 'WEBVTT'">
            <p class="m-0">[{{convertSecondsToTimeFormat(sub.start)}}]</p>
            <span [class.current-highlight]="isActiveSubtitle(sub)" (click)="seekToTime(sub.start)"
              [innerHTML]="sub.highlightedText || sub.text"></span>
          </ng-container>
        </p>
      </div>



    </div>
  </div>
</div>
<ng-template #viewVideoUrl>
  <div class="d-flex video_url">
    <iframe [src]="videoUrl" allow="autoplay; fullscreen; picture-in-picture; clipboard-write"></iframe>
  </div>
</ng-template>

