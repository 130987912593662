import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SwalService } from 'src/app/services/swal.service';

export interface DialogData {
  title: string;
  image: string;
  message: string;
  serviceTarget: string;
  service: any;
  btnSubmitTitle: string;
  swalMessageSuccess: string;
  record: any;
  btnSubmitClass: string;
  btnCloseTitle: string;
}
@Component({
  selector: 'app-horizontal-confirmation',
  templateUrl: './horizontal-confirmation.component.html'
})

export class HorizontalConfirmationComponent implements OnInit {
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<HorizontalConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {}

  confirm() {
    if (this.data.serviceTarget) {
      this.loading = true;
      const service = this.data.service;
      service[this.data.serviceTarget](this.data.record).subscribe(
        () => {
          this.loading = false;
          this.dialogRef.close(true);
        },
        (err) => {
          this.loading = false;
          this.handleErrors(err.detail);
        }
      );
    } else {
      this.dialogRef.close(true);
    }
  }

  handleErrors(errors) {
    errors = JSON.parse(errors);
  }
}
