import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CourseModules, ModuleLesson, ModuleLessonDetails } from 'src/app/models/courses/Courses';
import * as saveAs from 'file-saver';
import { CoursesService } from 'src/app/services/Courses.service';
import { SwalService } from 'src/app/services/swal.service';
import { CoursesIntegrationService } from 'src/app/services/courses-integration.service';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
})
export class VideoPlayerComponent implements OnInit, AfterViewInit, OnDestroy {
  isPlaying = false;
  isFullScreen = false;
  isFinished = false;
  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  @ViewChild('videoContainer') videoContainer!: ElementRef;
  @Input() isLastLesson: boolean;
  @Input() btnLoading: boolean;
  @Input() activeLesson:ModuleLesson;
  @Input() details: ModuleLessonDetails;
  @Input() isPreviewContent: boolean;
  @Output() closesSidebarLeft = new EventEmitter<any>();
  @Output() markCompleted = new EventEmitter<any>();
  @Output() showMarkCompleted = new EventEmitter<any>();
  @Output() dataChange = new EventEmitter<any>();

  progress = 0;
  currentTime = 0;
  duration = 0;
  volume = 0.5; // Default volume level (50%)
  isMuted = false;
  showVolumeSlider = false;
  toggleTranslate = false;
  videoUrl:any

  searchQuery: string = ''; // User's search query
  @ViewChild('transcript') transcriptRef!: ElementRef; // Reference to transcript container
  searchResults: any[] = []; // Store highlighted elements
  currentIndex: number = -1; // Track current match index
  parsedSubtitles: any[] = [];
  trustedSubtitleUrl;

  constructor(
    private sanitizer: DomSanitizer,
    public service: CoursesService,
    private coursesIntegrationService: CoursesIntegrationService,
    private swalService:SwalService
  ) {}

  ngOnInit(): void {
    if (!this.details.videoUrl) {
      this.loadSubtitles();
      this.trustedSubtitleUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.details.transcriptionUrl
      );
    } else {
      this.youtubeUrlValidator(this.details.videoUrl)
    }
  }

  ngAfterViewInit(): void {
    if (!this.details.videoUrl) {
      this.videoPlayer.nativeElement.src =
        this.details.materialName;
      if (this.details.currentPlayTime) {
        this.videoPlayer.nativeElement.currentTime =
          this.details.currentPlayTime;
      }
      this.setInitialVolume();
    } else {
      this.youtubeUrlValidator(this.details.videoUrl)
    }
  }

  async loadSubtitles() {
    const response = await fetch(this.details.transcriptionUrl); // Adjust the path if necessary
    const vttText = await response.text();
    this.parsedSubtitles = this.parseVTT(vttText);
  }
  // Custom VTT parser
  parseVTT(data: string) {
    const lines = data.split('\n').map((line) => line.trim());
    const subtitles = [];
    let start = 0;
    let end = 0;
    let text = '';

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];

      const timestampMatch = line.match(
        /(\d{2}:\d{2}(?::\d{2})?[\.,]\d{3}) --> (\d{2}:\d{2}(?::\d{2})?[\.,]\d{3})/
      );

      if (timestampMatch) {
        if (text) {
          subtitles.push({ start, end, text: text.trim() });
          text = '';
        }
        start = this.timeStringToSeconds(timestampMatch[1]);
        end = this.timeStringToSeconds(timestampMatch[2]);
      } else if (line) {
        text += (text ? '\n' : '') + line;
      }
    }

    if (text) {
      subtitles.push({ start, end, text: text.trim() });
    }

    return subtitles;
  }

  // Convert VTT time format to seconds
  timeStringToSeconds(timeString: string): number {
    const match = timeString.match(
      /(?:(\d{2}):)?(\d{2}):(\d{2}|\d{2}[\.,]\d{3})/
    );
    if (!match) return 0;

    const hours = parseInt(match[1] || '0', 10); // Hours are optional
    const minutes = parseInt(match[2], 10);
    const [seconds, milliseconds] = (match[3] || '0.000')
      .split(/[\.,]/)
      .map(Number);

    return hours * 3600 + minutes * 60 + seconds + (milliseconds || 0) / 1000;
  }

  convertSecondsToTimeFormat(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (hours > 0) {
      // Format as HH:MM:SS
      return `${this.padWithZero(hours)}:${this.padWithZero(
        minutes
      )}:${this.padWithZero(remainingSeconds)}`;
    } else {
      // Format as MM:SS
      return `${this.padWithZero(minutes)}:${this.padWithZero(
        remainingSeconds
      )}`;
    }
  }

  padWithZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  // Sync subtitles with video time
  syncSubtitles() {
    const currentTime = this.videoPlayer.nativeElement.currentTime;

    this.parsedSubtitles.forEach((sub) => {
      sub.active = currentTime >= sub.start && currentTime <= sub.end;
    });
  }

  setInitialVolume() {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    video.volume = this.volume;
  }

  videoEnded() {
    if (!this.isPreviewContent) {
      this.isFinished = true;
      const video: any = document.getElementById('myVideo');
      video.pause();
      if (!this.activeLesson.isCompleted) {
        this.markCompleted.emit(true);
        //this.MarkLessonIsCompleted();
      }
    }
  }

  togglePlay(event: Event) {
    // Prevent default link behavior if play/pause triggered from video click
    event.preventDefault();

    const video: any = document.getElementById('myVideo');
    if (video.paused) {
      video.play();
      this.isPlaying = true;
    } else {
      video.pause();
      this.isPlaying = false;
    }
  }

  toggleFullScreen() {
    const container: HTMLElement = this.videoContainer.nativeElement;
    if (!document.fullscreenElement) {
      container.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  updateProgress() {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    this.currentTime = video.currentTime;
    this.progress = (video.currentTime / video.duration) * 100;
    if (this.progress > 10) {
      this.showMarkCompleted.emit(true)
    }
  }

  setDuration() {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    this.duration = video.duration;
  }

  seekTo(event: MouseEvent) {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    const progressBar = event.currentTarget as HTMLElement;

    // Calculate where the user clicked on the progress bar
    const clickPositionX = event.offsetX;
    const progressBarWidth = progressBar.clientWidth;

    const clickRatio = clickPositionX / progressBarWidth;
    video.currentTime = clickRatio * video.duration;
  }

  updatePercentage(video: HTMLVideoElement) {
    const currentTime = video.currentTime;
    this.details.currentPlayTime = video.currentTime;
    const duration = video.duration;
    if (duration > 0) {
      this.details.percentage = (currentTime / duration) * 100;
    } else {
      this.details.percentage = 0;
    }
  }

  formatTime(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    return hours > 0
      ? `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(secs)}`
      : `${this.pad(minutes)}:${this.pad(secs)}`;
  }

  pad(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  changeVolume(event: Event) {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    const input = event.target as HTMLInputElement;
    this.volume = parseFloat(input.value);
    video.volume = this.volume;
  }

  toggleMute() {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    this.isMuted = !this.isMuted;
    video.muted = this.isMuted;
  }

  toggleVolumeSlider() {
    this.showVolumeSlider = !this.showVolumeSlider;
  }

  openTranslate() {
    this.toggleTranslate = !this.toggleTranslate;
    this.closesSidebarLeft.emit(!this.toggleTranslate);
  }

  // Handle search input changes
  onSearch() {
    this.searchResults = []; // Reset search results

    if (this.searchQuery.trim()) {
      this.highlightMatches(); // Highlight matching words
      this.currentIndex = -1; // Reset navigation index
    }
  }

  // Highlight matching words in the subtitles
  highlightMatches() {
    const query = this.searchQuery.trim().toLowerCase();

    this.parsedSubtitles.forEach((subtitle, index) => {
      const regex = new RegExp(`(${query})`, 'gi');

      if (subtitle.text.toLowerCase().includes(query)) {
        const highlightedText = subtitle.text.replace(
          regex,
          `<span class="highlight">$1</span>`
        );

        // Save the highlighted text back into the subtitle object
        this.parsedSubtitles[index] = { ...subtitle, highlightedText };
        this.searchResults.push(index); // Store index for navigation
      } else {
        // Reset to original text if there's no match
        this.parsedSubtitles[index].highlightedText = subtitle.text;
      }
    });
  }

  // Clear search input and reset highlights
  clearSearch() {
    this.searchQuery = '';
    this.parsedSubtitles.forEach((subtitle, index) => {
      this.parsedSubtitles[index].highlightedText = subtitle.text;
    });
    this.searchResults = [];
  }

  // Navigate between highlighted search results
  navigateResults(direction: 'up' | 'down') {
    if (!this.searchResults.length) return;

    // Remove the current highlight
    if (this.currentIndex >= 0) {
      const previousIndex = this.searchResults[this.currentIndex];
      this.parsedSubtitles[previousIndex].isActive = false;
    }

    // Update the current index based on the direction
    if (direction === 'up') {
      this.currentIndex =
        (this.currentIndex - 1 + this.searchResults.length) %
        this.searchResults.length;
    } else {
      this.currentIndex = (this.currentIndex + 1) % this.searchResults.length;
    }

    // Highlight the new current result and scroll it into view
    const newIndex = this.searchResults[this.currentIndex];
    this.parsedSubtitles[newIndex].isActive = true;

    // Scroll the new active element into view
    const element = document.getElementById(`subtitle-${newIndex}`);
    element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  // Check if subtitle is active to apply highlight class
  isActiveSubtitle(sub: any): boolean {
    return sub.active;
  }

  // Seek video to the time of the selected word
  seekToTime(time: any) {
    this.videoPlayer.nativeElement.currentTime = time;
    this.videoPlayer.nativeElement.play();
    this.isPlaying = true;
  }

  downloadFile() {
    let lessonName;
    const moduleNameIndex =
      this.details.transcriptionUrl.indexOf('ModuleLessons/');
    if (moduleNameIndex !== -1) {
      lessonName = this.details.transcriptionUrl.substring(
        moduleNameIndex + 'ModuleLessons/'.length
      );
    }
    saveAs(this.details.transcriptionUrl, lessonName);
  }

  downloadVideo () {
    let lessonName;
    const moduleNameIndex = this.details.materialName.indexOf('ModuleLessons/');
    if (moduleNameIndex !== -1) {
      lessonName = this.details.materialName.substring( moduleNameIndex + 'ModuleLessons/'.length);
    }
     let model = {
      lessonId: this.details.id,
    };
    this.coursesIntegrationService.countDownloadLesson(model).subscribe(
      (data) => {
        saveAs(this.details.materialName, lessonName);
        // this.swalService.swalSuccess();
        this.dataChange.emit(true)
       },
      (error) => {
       }
    );
  }

  youtubeUrlValidator(url) {

    // YouTube URL Regex (supports both short and long URLs)
    const youtubeRegex =
      /(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\?v=))([a-zA-Z0-9_-]{11})/;
    const vimeoRegex = /^(https?:\/\/)?(www\.)?vimeo\.com\/(\d+)$/;

    const valid = youtubeRegex.test(url) || vimeoRegex.test(url);
    if (vimeoRegex.test(url)) {
      const match = url.match(vimeoRegex);
      if (match && match[3]) {
        const videoId = match[3];
        this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          `https://player.vimeo.com/video/${videoId}`
        );
      }
    }
    if (youtubeRegex.test(url)) {
      const match = url.match(youtubeRegex);
      if (match && match[1]) {
        const videoId = match[1];
        this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          `https://youtube.com/embed/${videoId}`
        );
      }
    }
  }

  ngOnDestroy(): void {
    if (this.videoPlayer) {
      this.videoPlayer.nativeElement.src = '';
    }
    this.videoUrl = ''
  }
}
