<div mat-dialog-title>
  <h2>
    {{data.fileName}}
  </h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content>
  <div [ngClass]="{'pttx-cantDownload':fileType(data.fileUrl) == 'pptx'}">
    <iframe class="w-100 border-0" [ngStyle]="{ 'pointer-events':'none' }" style="height: 759px;" [src]="url"></iframe>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end" class="assign-dialog-actions justify-content-end">
  <div>
    <button mat-raised-button *ngIf="data.canDownloadIt" color="primary" [class.loading]="loading" [disabled]="loading" (click)="downloadFile()">{{"openContent.downloadFile"|translate}}</button>
    <button mat-raised-button color="grey" mat-dialog-close>{{"swalMsg.cancel"|translate}}</button>
  </div>
</mat-dialog-actions>
