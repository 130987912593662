<div class="app-chat">

  <!-- (click)="chatSeen(chat.user.id)" -->
  <div class="chat-rooms">

    <div class="chat-container room" [class.active]='chat.active' *ngFor="let chat of activeChats; index as i">
      <div class="chat-head" (click)='chat.active = !chat.active'>
        <div class="name-with-image mb-3 mb-sm-0">
          <div class="image-circle" *ngIf="!chat.isGroupChat">
            <img *ngIf="chat.user.image" [src]="chat.user.image" (error)="chat.user.image = ''" />
            <span *ngIf="!chat.user.image">{{ chat.user.name | shortName }}</span>
          </div>
          <div class="name">
            <ng-container *ngIf="!chat.isGroupChat && chatType == chatTypeEnum.COURSE">
              <h5>{{chat.user.name}}</h5>
              <p>
                {{course.title?course.title:course.titleEn}}
              </p>
            </ng-container>

            <ng-container *ngIf="chat.isGroupChat && chatType == chatTypeEnum.COURSE">
              <h5>{{course.title?course.title:course.titleEn}}</h5>
              <p>
                {{'courseDetails.groupChat' | translate}}
              </p>
            </ng-container>
          </div>
        </div>

        <button mat-icon-button class="btn-close" (click)='removeChat(i)'>
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <div class="chat-area-container">
        <div class="chat-area scroll-area" [id]="'chat-' + i">
          <div class="dataLoading" *ngIf='!chat.msgList.length && chat.loading'>
            <mat-spinner class="m-auto" diameter="50"></mat-spinner>
          </div>

          <ng-container *ngIf="!chat.loading">
            <div class="no-message" *ngIf="!chat.msgList.length">
              <div>
                <img src="./assets/images/icons/chat-empty.svg" alt="">
                <p>No messages yet</p>
              </div>
            </div>

            <ul *ngIf="chat.msgList.length">
              <li *ngFor="let message of chat.msgList" [class.mine]='message.mine'>
                <div class="name-with-image"
                  *ngIf="!message.mine && message.isGroupChat && chatType == chatTypeEnum.COURSE">
                  <div class="image-circle">
                    <img *ngIf="message.fromImage" [src]="message.fromImage" (error)="message.fromImage = ''" />
                    <span *ngIf="!message.fromImage">{{ message.fromName | shortName }}</span>
                  </div>
                </div>

                <div>
                  <h6 *ngIf="!message.mine && message.isGroupChat && chatType == chatTypeEnum.COURSE">
                    {{message.fromName}}
                  </h6>
                  <p>
                    {{ message.text }}
                  </p>
                  <p class="time">{{ message.time | date:'hh:mm aaa' }}</p>
                </div>
              </li>
            </ul>
          </ng-container>
        </div>
        <div class="send-form"
          *ngIf="(chat.user && chat.user.canChat && chat.user.canViewHistory) || chatType == chatTypeEnum.COURSE">
          <div>
            <input type="text" placeholder="Write your message" [(ngModel)]='chat.message' (keydown.enter)='send(chat)'>
            <button mat-icon-button class="btn" (click)='send(chat)'>
              <img src="./assets/images/icons/ic_send.svg" alt="">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>