import { ContentTypeEnum } from "./enums/ContentTypeEnum"
import { ModuleStatusEnum } from "./enums/ModuleStatusEnum";

export class CourseDurationProgress {
  courseId: number;
  courseDuration: string;
  percentage: number;
  modules: Module[];
  caseStudy: Module[];
  finalAssessment: Module;
  preAssessment: Module;
  postAssessment: Module;
  upComingActivities: UpComingActivities[];
  notAllowed: boolean;
}

export class Module {
  id: number
  title: string
  moduleOrderTitle: string
  order: number
  totalDuration: string
  remainingDuration: string
  isCompleted: boolean
  lessons: Lesson[]
  status: ModuleStatusEnum;
}

export interface Lesson {
  id: number
  title: string
  contentType: ContentTypeEnum;
  durationMinutes: number
  durationHours: number
  isCompleted: boolean
  liveSessionDueDate: Date;
  assignmentDueDate: Date;
}

export interface UpComingActivities {
  id: number
  title: string
  moduleOrderTitle: string
  order: number
  totalDuration: string
  remainingDuration: string
  isCompleted: boolean
  lessons: Lesson[]
}
