import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CourseDetail, CurrentView } from '../models/courses/Courses';
import { AuthenticationService } from './authentication.service';
import { RolesEnum } from '../models/users/roles/enums/roles';

@Injectable({
  providedIn: 'root',
})
export class CoursesService {
  showContent = new Subject<CurrentView>();
  nextContent = new Subject<boolean>();
  selectedModuel = new Subject<any>();
  refreshDetails = new BehaviorSubject<any>(false);
  refreshLessonDetails = new BehaviorSubject<any>(false);
  disabledActions = new BehaviorSubject<any>(false);
  openSearchSideNav = new Subject<any>();

  constructor(private http: HttpClient,
    private auth: AuthenticationService) { }

  show(type, ModuleIndex = null, LessonIndex = null) {
    this.showContent.next({ type, ModuleIndex, LessonIndex });
  }

  showNav(type, ModuleIndex = null, LessonIndex = null, isCompleted = false, nextModuleLessonId, level, module = null, selectedSearchId = null) {
    console.log(level);

    if (this.hasPermission(RolesEnum.Trainee)) {
      if (isCompleted || module?.isPreDiagnose || (selectedSearchId ? selectedSearchId == level.id : nextModuleLessonId == level.id)) {
        this.showContent.next({ type, ModuleIndex, LessonIndex });
        level.isActive = true
      }
    } else {
      this.showContent.next({ type, ModuleIndex, LessonIndex });
      level.isActive = true
    }
  }

  hasPermission(permission: string, checkOtherRoles = false) {
    return this.auth.hasPermission(permission, checkOtherRoles);
  }

  getAll(params): any {
    return this.http.get(`${environment.apiUrl}/course/TraineeEnrolledCourse`, {
      params,
    });
  }

  GetModulesByCourseId(id) {
    return this.http.get(
      `${environment.apiUrl}/CourseModule/GetModulesByCourseId?CourseId=${id}`
    );
  }
  submitAnswers(model) {
    return this.http.post(
      `${environment.apiUrl}/TraineeCourse/SubmitAnswers`,
      model
    );
  }

  submitAnswerAsDraft(model) {
    return this.http.post(
      `${environment.apiUrl}/TraineeCourse/SubmitAnswerAsDraft`,
      model
    );
  }

  ShowAnswers(id) {
    return this.http.get(
      `${environment.apiUrl}/TraineeCourse/ShowAnswers?QuizId=${id}`
    );
  }

  MarkLessonIsCompleted(model) {
    return this.http.post(
      `${environment.apiUrl}/TraineeCourse/MarkLessonIsCompleted?lessonId=${model.lessonId}&CourseId=${model.courseId}&percentage=${model.percentage}&currentPlayTime=${model.currentPlayTime}`,
      null
    );
  }
  // setCompletionDateTrainee(courseId) {
  //   return this.http.put(
  //     `${environment.apiUrl}/Course/SetCompletionDateTrainee?CourseId=${courseId}`,
  //     null
  //   );
  // }
  calculatePercentageQuiz(id) {
    return this.http.get(
      `${environment.apiUrl}/TraineeCourse/CalculatePercentageQuiz?QuizId=${id}`
    );
  }
  getCourseStreamToken(id) {
    return this.http.get(
      `${environment.apiUrl}/Course/GetCourseStreamToken?CourseId=${id}`
    );
  }
  GoLive(id) {
    return this.http.put(`${environment.apiUrl}/Course/GoLive?CourseId=${id}`, null);
  }
  GoOffline(id) {
    return this.http.put(`${environment.apiUrl}/Course/GoOffline?CourseId=${id}`, null);
  }
  getPrePostAssessmentQuiz(filter) {
    return this.http.get(`${environment.apiUrl}/TraineeCourse/GetPrePostAssessmentQuiz`, {
      params: filter,
    });
  }

  getPreOrPostAssessmentGraphOfData(params) {
    return this.http.get(`${environment.apiUrl}/TraineeCourse/GetPreOrPostAssessmentGraphOfData`, { params });
  }

  getTraineePreAssessmentReportCard(id: number) {
    return this.http.get(`${environment.apiUrl}/TraineeCourse/GetTraineePreAssessmentReportCard?CourseId=${id}`);
  }

  getTraineePostAssessmentReportCard(id: number) {
    return this.http.get(`${environment.apiUrl}/TraineeCourse/GetTraineePostAssessmentReportCard?CourseId=${id}`);
  }

  calculateTraineeScoreChanges(id: number) {
    return this.http.get(`${environment.apiUrl}/TraineeCourse/CalculateTraineeScoreChanges?CourseId=${id}`);
  }

  calculateUserScoreChangesPerSlide(id: number) {
    return this.http.get(`${environment.apiUrl}/TraineeCourse/CalculateUserScoreChangesPerSlide?CourseId=${id}`);
  }

  getFinalAssessmentGraph(id: number) {
    return this.http.get(`${environment.apiUrl}/TraineeCourse/GetFinalAssessmentGraph?CourseId=${id}`);
  }

  getFinalAssessmentGraphForTrainee(id: number) {
    return this.http.get(`${environment.apiUrl}/TraineeCourse/GetFinalAssessmentGraphForTrainee?CourseId=${id}`);
  }

  getTraineeProgressDiagram(id: number) {
    return this.http.get(`${environment.apiUrl}/CourseModule/GetTraineeProgressDiagram?ModuleId=${id}`);
  }

  activeCourse(id, isDeActiva) {
    if (isDeActiva) {
      return this.http.put(`${environment.apiUrl}/Course/DeActiveCourse?CourseId=${id}`, null);
    } else {
      return this.http.put(`${environment.apiUrl}/Course/ActiveCourse?CourseId=${id}`, null);
    }
  }

  assessmentExitLog(id: number) {
    return this.http.post(`${environment.apiUrl}/TraineeCourse/AssessmentExitLog?ModuleLessonId=${id}`, null);
  }

  setUserAttendMeeting(params) {
    return this.http.get(`${environment.apiUrl}/LiveSession/SetUserAttendMeeting`, { params });
  }
  getSearchInCourseLessonsByKeywords(params) {
    return this.http.get(`${environment.apiUrl}/CourseModule/SearchInCourseLessonsByKeywords`, { params });
  }

  SubmitOrUpdateTraineeAssignmentAnswer(model, files) {
    const formData = new FormData();
    if (files && files.length) {
      files.forEach(file => {
        if (!file?.id) {
          formData.append('TraineeAssignmentAttachments', file, file.name);
        }
      })
    } else {
      formData.append('TraineeAssignmentAttachments', '');
    }
    formData.append('data', JSON.stringify(model));
    return this.http.post(`${environment.apiUrl}/CourseModule/SubmitOrUpdateTraineeAssignmentAnswer`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  evaluateCourseForUser(params) {
    return this.http.get(`${environment.apiUrl}/TraineeCourse/EvaluateCourseForUser`, { params });
  }

  verifyID(model) {
    const formData = new FormData();
    formData.append('files', model.file);
    formData.append('data', JSON.stringify({ LessonId: model.LessonId }));
    return this.http.post(`${environment.apiUrl}/TraineeIdentityVerification/verify`, formData);
  }

  requestReviewIdentity(data) {
    return this.http.put(`${environment.apiUrl}/TraineeIdentityVerification/RequestReviewIdentity`, data);
  }

  finalExamSeen(data) {
    return this.http.put(`${environment.apiUrl}/Course/FinalExamSeen`, data);
  }

  getSubtitleFile(url: string): Observable<string> {
    return this.http.get(url, { responseType: 'text' });
  }

  parseSRT(data: string): any[] {
    const subtitles = [];
    const srtBlocks = data.split('\n\n');

    srtBlocks.forEach(block => {
      console.log(block);

      const lines = block.split('\n');
      if (lines.length >= 3) {
        const index = lines[0];
        const time = lines[1];
        const text = lines.slice(2).join('\n');

        subtitles.push({ index, time, text });
      }
    });

    return subtitles;
  }


  getCourseSideSheetForTrainee(courseId) {
    return this.http.get(`${environment.apiUrl}/CourseModule/GetCourseSideSheetForTrainee?CourseId=${courseId}`);
  }

  getCourseSideSheet(courseId) {
    return this.http.get(`${environment.apiUrl}/CourseModule/GetCourseSideSheet?CourseId=${courseId}`);
  }

  withdrawTrainee(params: any) {
    return this.http.get(`${environment.apiUrl}/Course/WithdrawTrainee`, { params });
  }

}
