import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
})
export class EmptyStateComponent implements OnInit {
  @Input() title: boolean;
  @Input() desc: boolean;
  @Input() img: string;
  @Input() animationPath: string;
  @Input() custClass: string;
  @Input() btnClass: string;
  @Output() eventEmit = new EventEmitter<any>();
  @Input() link: string;
  @Input() linkBoolean: boolean;
  options: AnimationOptions;

  constructor() { }

  ngOnInit(): void {
    this.options = {
      path: this.animationPath || '/assets/js/empty-state-animated.json',
      loop: false
    };
  }

  evEmit() {
    this.eventEmit.emit();
  }

}
