import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ConcernedStaff } from '../models/cases/ConcernedStaff';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  newChatGroup = new BehaviorSubject<boolean>(false);
  newChatUser = new BehaviorSubject<ConcernedStaff | any>(null);
  messagesList = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) { }

  SendChatMessage(params) {
    return this.http.post(`${environment.apiUrl}/Chat/SendChatMessage`, params);
  }

  ListChatMessages(params) {
    return this.http.get(`${environment.apiUrl}/Chat/ListChatMessages`, { params });
  }

  SeeConversation(userId) {
    return this.http.get(`${environment.apiUrl}/Chat/SeeConversation?userId=` + userId);
  }

  GetMissedConversations() {
    return this.http.get(`${environment.apiUrl}/Chat/GetMissedConversations`);
  }

  ListCourseChatMessages(params) {
    return this.http.get(`${environment.apiUrl}/Chat/ListCourseChatMessages`, { params });
  }
}
