<div mat-dialog-title>
  <h2>
    {{ (data.type == surveyType.PLATFORM ? 'survey.sendPlatformSurvey' : 'survey.sendCourseSurvey')|translate }}
  </h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content>
  <div class="mat-dialog-body">
    <div *ngIf='dataLoading' class="dataLoading">
      <mat-spinner class="m-auto" diameter="50"></mat-spinner>
    </div>
    <form [formGroup]="formGroup" [hidden]="dataLoading">
      <div class="fields-group" [hidden]="data.type !== surveyType.COURSE">
        <mat-form-field>
          <mat-label>{{"survey.selectCourse"|translate}}</mat-label>
          <mat-select disableOptionCentering formControlName="courseId" [required]="data.type == surveyType.COURSE">
            <mat-option [value]="item.id" *ngFor="let item of coursesList">
              <span>{{item.name}}</span>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="hasError('courseId', 'required')">
            {{ 'forms.Required' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="fields-group">
        <h3 class="fields-title">{{"survey.surveyExpiration"|translate}}</h3>
        <mat-form-field>
          <mat-label>{{"survey.surveyExpiresAfter"|translate}}</mat-label>
          <mat-select disableOptionCentering formControlName="surveyExpiration">
            <mat-option [value]="item.id" *ngFor="let item of expirationList">
              <span>{{item.name}}</span>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="hasError('surveyExpiration', 'required')">
            {{ 'forms.Required' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="mat-dialog-inner">
        <div class="row">
          <div class="col-12">
            <div class="fields-group">
              <mat-slide-toggle formControlName="sendForUsersNotReceived" [labelPosition]="'before'">
                <p>{{"survey.sendSurveyInstruction"|translate}}?</p>
              </mat-slide-toggle>
            </div>
          </div>

        </div>
      </div>
      <button class="w-100" mat-raised-button color="primary" [disabled]="loading" [class.loading]='loading'
        (click)="saveRecord()">
        {{'survey.sendSurvey' |translate }}
      </button>
    </form>
  </div>
</mat-dialog-content>
