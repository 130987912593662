import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  refreshDetails = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) {
  }

  saveRecord(data): any {
    if (data.id) {
      return this.http.put(`${environment.apiUrl}/Survey/UpdateSurvey`, data);
    } else {
      return this.http.post(`${environment.apiUrl}/Survey/CreateSurvey`, data);
    }
  }

  getAll(params: any): any {
    return this.http.get(`${environment.apiUrl}/Survey/FilterSurvey`, { params });
  }

  getDetails(id: number) {
    return this.http.get(`${environment.apiUrl}/Survey/GetSurveyDetails?surveyId=${id}`);
  }

  deleteRecord(id): any {
    return this.http.delete(`${environment.apiUrl}/Survey/DeleteSurvey?Id=${id}`);
  }

  filterCourseSurvey(params: any): any {
    return this.http.get(`${environment.apiUrl}/Survey/FilterCourseSurvey`, { params });
  }

  getSurveyQuestions(id: number) {
    return this.http.get(`${environment.apiUrl}/Survey/GetSurveyQuestionsForFill?sentRecordId=${id}`);
  }

  fillSurvey(data) {
    return this.http.post(`${environment.apiUrl}/Survey/FillSurvey`, data);
  }

  sendSurvey(data): any {
    return this.http.post(`${environment.apiUrl}/Survey/SendSurvey`, data);
  }

  getSurveyReport(params:any) {
    return this.http.get(`${environment.apiUrl}/Survey/GetSurveyReport`, {params});
  }
}
