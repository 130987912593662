<div *ngIf='dataLoading' class="dataLoading">
  <mat-spinner class="m-auto" diameter="50"></mat-spinner>
</div>

<ng-container *ngIf='!dataLoading'>
  <div class="details_module ">
    <div class="sidenav-user-toolbar">
      <h4>
        {{(forTrainee?"courses.courseDurationAndProgress":"courses.courseDuration")|translate}}
      </h4>
    </div>
    <div class="sidenav-user-container">
      <div class="page">
        <div class="profile_detail pb-0">
          <div class="row mb-3 mx-0">
            <div class="col-md-12 mb-3 px-0" [ngClass]="{'col-lg-6':forTrainee}">
              <div class="card_sub_info_statistics h-100 text-center mr-0" [ngClass]="{'mr-lg-4':forTrainee}">
                <div class="card_body">
                  <h4>{{"courses.courseDuration"|translate}}</h4>
                  <p>
                    <span class="icon">
                      <img src="./assets/images/icons/ic_duration_outline.svg" alt="">
                    </span>
                    {{details.courseDuration}}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 mb-3 px-0" *ngIf="forTrainee">
              <div class="card_sub_info_statistics h-100 text-center">
                <div class="card_body">
                  <h4>{{"courses.courseProgress"|translate}}</h4>
                  <p>
                    <span class="icon">
                      <svg class="progress_circle_mini" [class.bg_grey]="details.notAllowed" viewBox="0 0 100 100">
                        <circle class="overlay" cx="50%" cy="50%" r="40" fill="none"></circle>
                        <circle *ngIf="details.percentage" [style.strokeDashoffset]="100 - details.percentage"
                          class="percent" cx="50%" cy="50%" fill="none" pathLength="100" r="40"></circle>
                      </svg>
                    </span>
                    {{details.percentage}}%
                  </p>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="details.upComingActivities && details.upComingActivities.length">
            <h4 class="section_title px-0">
              {{"courses.upcomingActivities"|translate}}
            </h4>
            <ng-container *ngFor="let item of details.upComingActivities">
              <div class="card_sub_info_activities" *ngFor="let lesson of item.lessons">
                <div class="card_body">
                  <ul class="meta_data_list">
                    <li>
                      <span>{{item.moduleOrderTitle}}</span>
                    </li>
                    <li>
                      <ng-container *ngIf="lesson.contentType == contentTypeLesson.ASSIGNMENT">
                        {{"courses.assignment"|translate}}
                      </ng-container>
                      <ng-container *ngIf="lesson.contentType == contentTypeLesson.LiveSession">
                        {{"courses.liveSession"|translate}}
                      </ng-container>
                    </li>
                  </ul>
                  <p class="title">
                    {{lesson.title}}
                  </p>
                  <p class="container_date_time">
                    <span>{{"courses.dueDateAndTime"|translate}}:</span>
                    <ng-container *ngIf="lesson.contentType == contentTypeLesson.ASSIGNMENT">
                      {{lesson.assignmentDueDate | date:'d MMM, y - h:mm a'}}
                    </ng-container>
                    <ng-container *ngIf="lesson.contentType == contentTypeLesson.LiveSession">
                      {{lesson.liveSessionDueDate | date:'d MMM, y - h:mm a'}}
                    </ng-container>
                  </p>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <div class="custom_divider"></div>
        </div>
        <ng-container *ngIf="details.preAssessment">
          <h4 class="section_title">
            {{details.preAssessment.moduleOrderTitle}}
          </h4>
          <mat-accordion class="custom_accordion_grey">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{details.preAssessment.moduleOrderTitle}}
                </mat-panel-title>
                <mat-panel-description>
                  <p>
                    {{details.preAssessment.title}}
                  </p>
                  <ul class="meta_data_list">
                    <li>
                      <img src="./assets/images/icons/ic_duration_outline.svg" alt="">
                      {{details.preAssessment.totalDuration}}
                    </li>
                    <ng-container *ngIf="forTrainee">
                      <li class="default" *ngIf="details.preAssessment.status == moduleStatus.INPROGRESS">
                        {{details.preAssessment.remainingDuration}}
                      </li>
                      <li class="default" *ngIf="details.preAssessment.status == moduleStatus.NOTSTARTED">
                        {{'courses.notStarted' | translate}}
                      </li>
                      <li class="completed" *ngIf="details.preAssessment.status == moduleStatus.COMPLETED">
                        {{'courses.completed' | translate}}
                      </li>
                      <li class="warning" *ngIf="details.preAssessment.status == moduleStatus.INPROGRESS">
                        {{'courses.inProgress' | translate}}
                      </li>
                    </ng-container>

                  </ul>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="card_item_module" *ngFor="let lesson of details.preAssessment.lessons">
                <div class="card_body">
                  <div class="card_content">
                    <h4 class="card_title">
                      <!-- <span class="card_type">{{lesson.contentType}}:</span> -->
                      {{lesson.title}}
                    </h4>
                    <span class="data_time">
                      <img src="./assets/images/icons/ic_duration_outline.svg" alt="">
                      {{lesson.durationHours?lesson.durationHours + ' h':''}}
                      {{lesson.durationMinutes?lesson.durationMinutes + ' m':''}}
                    </span>
                  </div>
                  <div class="card_actions" *ngIf="lesson.isCompleted">
                    <img src="./assets/images/icons/done_check.svg" alt="">
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>

        <ng-container *ngIf="details.modules && details.modules.length">
          <h4 class="section_title">
            {{"courses.modules"|translate}}
          </h4>
          <mat-accordion class="custom_accordion_grey">
            <mat-expansion-panel *ngFor="let moduleRecord of details.modules">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{moduleRecord.moduleOrderTitle}}
                </mat-panel-title>
                <mat-panel-description>
                  <p>
                    {{moduleRecord.title}}
                  </p>
                  <ul class="meta_data_list">
                    <li>
                      <img src="./assets/images/icons/ic_duration_outline.svg" alt="">
                      {{moduleRecord.totalDuration}}
                    </li>
                    <ng-container *ngIf="forTrainee">
                      <li class="default" *ngIf="moduleRecord.status == moduleStatus.INPROGRESS">
                        {{moduleRecord.remainingDuration}}
                      </li>
                      <li class="default" *ngIf="moduleRecord.status == moduleStatus.NOTSTARTED">
                        {{'courses.notStarted' | translate}}
                      </li>
                      <li class="completed" *ngIf="moduleRecord.status == moduleStatus.COMPLETED">
                        {{'courses.completed' | translate}}
                      </li>
                      <li class="warning" *ngIf="moduleRecord.status == moduleStatus.INPROGRESS">
                        {{'courses.inProgress' | translate}}
                      </li>
                    </ng-container>
                  </ul>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="card_item_module" *ngFor="let lesson of moduleRecord.lessons">
                <div class="card_body">
                  <div class="card_content">
                    <h4 class="card_title">
                      <span class="card_type">{{lesson.contentType}}:</span>
                      {{lesson.title}}
                    </h4>
                    <span class="data_time">
                      <img src="./assets/images/icons/ic_duration_outline.svg" alt="">
                      {{lesson.durationHours?lesson.durationHours + ' h':''}}
                      {{lesson.durationMinutes?lesson.durationMinutes + ' m':''}}
                    </span>
                  </div>
                  <div class="card_actions" *ngIf="lesson.isCompleted">
                    <img src="./assets/images/icons/done_check.svg" alt="">
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>

        <ng-container *ngIf="details.caseStudy && details.caseStudy.length">
          <h4 class="section_title">
            {{"courses.caseStudies"|translate}}
          </h4>
          <mat-accordion class="custom_accordion_grey">
            <mat-expansion-panel *ngFor="let item of details.caseStudy">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{item.moduleOrderTitle}}
                </mat-panel-title>
                <mat-panel-description>
                  <p>
                    {{item.title}}
                  </p>
                  <ul class="meta_data_list">
                    <li>
                      <img src="./assets/images/icons/ic_duration_outline.svg" alt="">
                      {{item.totalDuration}}
                    </li>
                    <ng-container *ngIf="forTrainee">
                      <li class="default" *ngIf="item.status == moduleStatus.INPROGRESS">
                        {{item.remainingDuration}}
                      </li>
                      <li class="default" *ngIf="item.status == moduleStatus.NOTSTARTED">
                        {{'courses.notStarted' | translate}}
                      </li>
                      <li class="completed" *ngIf="item.status == moduleStatus.COMPLETED">
                        {{'courses.completed' | translate}}
                      </li>
                      <li class="warning" *ngIf="item.status == moduleStatus.INPROGRESS">
                        {{'courses.inProgress' | translate}}
                      </li>
                    </ng-container>
                  </ul>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="card_item_module" *ngFor="let lesson of item.lessons">
                <div class="card_body">
                  <div class="card_content">
                    <h4 class="card_title">
                      <!-- <span class="card_type">{{lesson.contentType}}:</span> -->
                      {{lesson.title}}
                    </h4>
                    <span class="data_time">
                      <img src="./assets/images/icons/ic_duration_outline.svg" alt="">
                      {{lesson.durationHours?lesson.durationHours + ' h':''}}
                      {{lesson.durationMinutes?lesson.durationMinutes + ' m':''}}
                    </span>
                  </div>
                  <div class="card_actions" *ngIf="lesson.isCompleted">
                    <img src="./assets/images/icons/done_check.svg" alt="">
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>
        <ng-container *ngIf="details.postAssessment">
          <h4 class="section_title">
            {{details.postAssessment.moduleOrderTitle|translate}}
          </h4>
          <mat-accordion class="custom_accordion_grey">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{details.postAssessment.moduleOrderTitle|translate}}
                </mat-panel-title>
                <mat-panel-description>
                  <p>
                    {{details.postAssessment.title}}
                  </p>
                  <ul class="meta_data_list">
                    <li>
                      <img src="./assets/images/icons/ic_duration_outline.svg" alt="">
                      {{details.postAssessment.totalDuration}}
                    </li>
                    <ng-container *ngIf="forTrainee">
                      <li class="default" *ngIf="details.postAssessment.status == moduleStatus.INPROGRESS">
                        {{details.postAssessment.remainingDuration}}
                      </li>
                      <li class="default" *ngIf="details.postAssessment.status == moduleStatus.NOTSTARTED">
                        {{'courses.notStarted' | translate}}
                      </li>
                      <li class="completed" *ngIf="details.postAssessment.status == moduleStatus.COMPLETED">
                        {{'courses.completed' | translate}}
                      </li>
                      <li class="warning" *ngIf="details.postAssessment.status == moduleStatus.INPROGRESS">
                        {{'courses.inProgress' | translate}}
                      </li>
                    </ng-container>
                  </ul>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="card_item_module" *ngFor="let lesson of details.postAssessment.lessons">
                <div class="card_body">
                  <div class="card_content">
                    <h4 class="card_title">
                      <!-- <span class="card_type">{{lesson.contentType}}:</span> -->
                      {{lesson.title}}
                    </h4>
                    <span class="data_time">
                      <img src="./assets/images/icons/ic_duration_outline.svg" alt="">
                      {{lesson.durationHours?lesson.durationHours + ' h':''}}
                      {{lesson.durationMinutes?lesson.durationMinutes + ' m':''}}
                    </span>
                  </div>
                  <div class="card_actions" *ngIf="lesson.isCompleted">
                    <img src="./assets/images/icons/done_check.svg" alt="">
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>
        <ng-container *ngIf="details.finalAssessment">
          <h4 class="section_title">
            {{details.finalAssessment.moduleOrderTitle|translate}}
          </h4>
          <mat-accordion class="custom_accordion_grey">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{details.finalAssessment.moduleOrderTitle|translate}}
                </mat-panel-title>
                <mat-panel-description>
                  <p>
                    {{details.finalAssessment.title}}
                  </p>
                  <ul class="meta_data_list">
                    <li>
                      <img src="./assets/images/icons/ic_duration_outline.svg" alt="">
                      {{details.finalAssessment.totalDuration}}
                    </li>
                    <ng-container *ngIf="forTrainee">
                      <li class="default" *ngIf="details.finalAssessment.status == moduleStatus.INPROGRESS">
                        {{details.finalAssessment.remainingDuration}}
                      </li>
                      <li class="default" *ngIf="details.finalAssessment.status == moduleStatus.NOTSTARTED">
                        {{'courses.notStarted' | translate}}
                      </li>
                      <li class="completed" *ngIf="details.finalAssessment.status == moduleStatus.COMPLETED">
                        {{'courses.completed' | translate}}
                      </li>
                      <li class="warning" *ngIf="details.finalAssessment.status == moduleStatus.INPROGRESS">
                        {{'courses.inProgress' | translate}}
                      </li>
                    </ng-container>
                  </ul>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="card_item_module" *ngFor="let lesson of details.finalAssessment.lessons">
                <div class="card_body">
                  <div class="card_content">
                    <h4 class="card_title">
                      <!-- <span class="card_type">{{lesson.contentType}}:</span> -->
                      {{lesson.title}}
                    </h4>
                    <span class="data_time">
                      <img src="./assets/images/icons/ic_duration_outline.svg" alt="">
                      {{lesson.durationHours?lesson.durationHours + ' h':''}}
                      {{lesson.durationMinutes?lesson.durationMinutes + ' m':''}}
                    </span>
                  </div>
                  <div class="card_actions" *ngIf="lesson.isCompleted">
                    <img src="./assets/images/icons/done_check.svg" alt="">
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>

      </div>
    </div>
  </div>
</ng-container>