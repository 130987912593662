<mat-dialog-content class="withdraw_request">
  <div class="mat-dialog-body">
    <div class="row mx-0 align-items-end">
      <div class="col-lg-4">
        <figure class="text-center">
          <img class="img-fluid" [src]="data.image" alt="">
        </figure>
      </div>
      <div class="col-lg-8">
        <h4 class="subtitle-form" *ngIf="data.title">
          {{data.title}}
        </h4>
        <p *ngIf="data.message" [innerHTML]="data.message">
          <!-- By doing so, you will lose all the current analysis data and results.
          Are you sure about that? -->
        </p>
        <div class="d-flex align-items-center justify-content-end">
          <button mat-raised-button mat-dialog-close color="grey" class="mr-2 mt-2" type="button">
            {{data.btnCloseTitle?data.btnCloseTitle:('swalMsg.cancel'|translate)}}
          </button>
          <button class="mt-2 {{data.btnSubmitClass}}" color="primary" mat-raised-button (click)="confirm()">
            {{data.btnSubmitTitle?data.btnSubmitTitle:('viewer.yesRunAnotherAnalysis'|translate)}}
          </button>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
