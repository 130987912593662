import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { SidebarComponent } from './layout/admin-layout/sidebar/sidebar.component';
import { HeaderComponent } from './layout/admin-layout/header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './partial/shared.module';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { BlankLayoutComponent } from './layout/blank-layout/blank-layout.component';
import { ErrorComponent } from './pages/error/error.component';
import { NotAuthComponent } from './pages/not-auth/not-auth.component';
import { AppCustomPreloader } from 'src/app/helpers/app-routing-loader';
import { SlidesListSharedComponent } from 'src/app/partial/slides/slides-list-shared/slides-list-shared.component';
import { ThemeLayoutComponent } from './layout/theme-layout/theme-layout.component';
import { ThemeHeaderComponent } from './layout/theme-layout/theme-header/theme-header.component';
import { ThemeFooterComponent } from './layout/theme-layout/theme-footer/theme-footer.component';
import { Directionality } from '@angular/cdk/bidi';
import { NotificationPreferenceComponent } from './partial/notification-preference/notification-preference.component';
import { DatePipe } from '@angular/common';
import { SendSurveyComponent } from './features/survey/survey-management/send-survey/send-survey.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    SidebarComponent,
    BlankLayoutComponent,
    ErrorComponent,
    NotAuthComponent,
    SlidesListSharedComponent,
    ThemeLayoutComponent,
    ThemeHeaderComponent,
    ThemeFooterComponent,
    NotificationPreferenceComponent,
    SendSurveyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    TranslateModule.forRoot({
      defaultLanguage: localStorage.getItem('language') || 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    })

  ],
  exports: [
    TranslateModule
  ],
  providers: [
    AppCustomPreloader,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: localStorage.getItem('language') || 'en' },
    DatePipe
    // { provide: Directionality, useValue: { value: localStorage.getItem('language') == "ar" ? "rtl" : "ltr" } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
