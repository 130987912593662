import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChatBox } from 'src/app/models/chat/chatBox';
import { ListDataFilter } from 'src/app/models/chat/ListDataFilter';
import { Message } from 'src/app/models/chat/message';
import { ConcernedStaff } from 'src/app/models/cases/ConcernedStaff';
import { CaseType } from 'src/app/models/cases/enums/CaseType';
import { ChatService } from 'src/app/services/chat.service';
import { ChatTypeEnum } from 'src/app/models/chat/enums/ChatType.enum';
import { CourseDetails } from 'src/app/models/trainer/training-course-details/CourseDetails';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html'
})
export class ChatComponent implements OnInit {
  typeId: number;
  loadingUsers = false;
  openChatList = false;
  filterModel: ListDataFilter = new ListDataFilter();
  searchText: string;

  activeChats: ChatBox[] = [];
  @Input() caseIdType: CaseType;
  chatTypeEnum = ChatTypeEnum;
  @Input() chatType: ChatTypeEnum = ChatTypeEnum.CASE;
  @Input() course: CourseDetails;
  maxRoomChat = 3;

  constructor(
    private chatService: ChatService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.updateMaxRooms();
    this.activatedRoute.paramMap.subscribe(params => {
      if (params.get('id')) {
        this.typeId = Number(params.get('id'));
      }
    });
    this.chatService.newChatGroup.subscribe(group => {
      if (group) {
        this.openChatGroup(group);
      }
    });
    this.chatService.newChatUser.subscribe(user => {
      if (user) {
        this.openChat(user);
      }
    });
  }

  openChat(user: ConcernedStaff) {
    const chatIndex = this.activeChats.findIndex((item) => item.user && item.user.userId === user.userId);
    if (chatIndex === -1) {
      this.activeChats.push({
        active: true,
        loading: true,
        user,
        message: '',
        msgList: [],
        isGroupChat: false
      });
      this.removeFirstChatRoom();
    } else {
      this.activeChats[chatIndex].loading = true;
      this.activeChats[chatIndex].active = true;
    }
    this.listChatMessages(user);
    // localStorage.setItem('activeChats', JSON.stringify(this.activeChats));
  }

  listChatMessages(user: ConcernedStaff) {
    let chatIndex = this.activeChats.findIndex((item) => item.user && item.user.userId === user.userId);
    this.filterModel = {
      userId: user.userId + '_' + user.userType
    };
    let serviceTarget = this.chatService.ListChatMessages(this.filterModel);
    if (this.chatType == ChatTypeEnum.CASE) {
      this.filterModel.CaseId = this.typeId;
      this.filterModel.CaseIdType = this.caseIdType;
    } else {
      this.filterModel.courseId = this.typeId;
      serviceTarget = this.chatService.ListCourseChatMessages(this.filterModel);
    }
    serviceTarget.subscribe(
      result => {
        this.chatService.messagesList.next(true);
        chatIndex = this.activeChats.findIndex((item) => item.user && item.user.userId === user.userId);
        if (chatIndex >= 0) {
          this.activeChats[chatIndex].msgList = result as Message[];
          this.activeChats[chatIndex].loading = false;
          this.scrollToChat(chatIndex);
        }
      }, err => {
        console.error(err);
      });
  }

  openChatGroup(group: boolean) {
    const chatIndex = this.activeChats.findIndex((item) => item.isGroupChat);
    if (chatIndex === -1) {
      this.activeChats.push({
        active: true,
        loading: true,
        isGroupChat: true,
        // user,
        message: '',
        msgList: []
      });
      this.removeFirstChatRoom();
    } else {
      this.activeChats[chatIndex].loading = true;
      this.activeChats[chatIndex].active = true;
    }
    this.ListCourseChatMessages();
    // localStorage.setItem('activeChats', JSON.stringify(this.activeChats));
  }

  ListCourseChatMessages() {
    let chatIndex = this.activeChats.findIndex((item) => item.isGroupChat);
    this.filterModel = {
      courseId: this.typeId
    };
    this.chatService.ListCourseChatMessages(this.filterModel).subscribe(
      result => {
        this.chatService.messagesList.next(true);
        chatIndex = this.activeChats.findIndex((item) => item.isGroupChat);
        if (chatIndex >= 0) {
          this.activeChats[chatIndex].msgList = result as Message[];
          this.activeChats[chatIndex].loading = false;
          this.scrollToChat(chatIndex);
        }
      }, err => {
        console.error(err);
      });
  }
  send(chat: ChatBox) {
    const self = this;
    if (chat.message) {
      const params = {
        text: chat.message
      } as Message;
      if (chat.isGroupChat) {
        params.isGroupChat = true;
      } else {
        params.toUserId = chat.user.userId;
        params.toUserType = chat.user.userType;
      }

      if (this.chatType == ChatTypeEnum.CASE) {
        params.caseID = this.typeId;
        params.caseIdType = this.caseIdType;
      } else {
        params.courseId = this.typeId;
      }
      this.chatService.SendChatMessage(params).subscribe(
        result => {
          const newMessage = {
            text: chat.message,
            mine: true,
            time: new Date(),
            isSeen: true
          } as Message;
          chat.msgList.push(newMessage);
          chat.message = '';
          this.scrollToChat(this.activeChats.indexOf(chat));
        }, err => {
          console.error(err);
        });
    }
  }
  removeChat(index) {
    if (this.activeChats[index].isGroupChat) {
      this.chatService.newChatGroup.next(null);
    } else {
      this.chatService.newChatUser.next(null);
    }
    this.activeChats.splice(index, 1);

    this.filterModel = new ListDataFilter();
    // localStorage.setItem('activeChats', JSON.stringify(this.activeChats));
  }

  removeFirstChatRoom() {
    if (this.activeChats.length > this.maxRoomChat) {
      this.activeChats.splice(0, 1);
    }
  }

  updateMaxRooms() {
    if (window && window.matchMedia('(max-width: 1200px)').matches) {
      this.maxRoomChat = 2;
    } else if (window && window.matchMedia('(max-width: 992px)').matches) {
      this.maxRoomChat = 1;
    } else {
      this.maxRoomChat = 3;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateMaxRooms();
  }

  scrollToChat(index) {
    setTimeout(() => {
      if (document.querySelectorAll('#chat-' + index + ' ul').length) {
        document.getElementById('chat-' + index).scrollTop =
          document.querySelectorAll('#chat-' + index + ' ul')[0].clientHeight;
      }
    }, 100);
  }
}
