 <mat-dialog-content class="confirmation">
  <button *ngIf="data.enableCloseBtn" type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="mat-dialog-body">
    <div class="d-flex justify-content-center" *ngIf="data.lottieFile">
      <ng-lottie width="250px" height="250px" [options]="options"></ng-lottie>
    </div>
    <figure class="mb-5 text-center" *ngIf="!data.lottieFile">
      <img class="img-fluid" [src]="data.img" alt="">
    </figure>
    <h4 class="label-form text-center bold" *ngIf="data.title" [innerHTML]="data.title">
    </h4>
    <p class="label-helper text-center mb-3" *ngIf="data.message" [innerHTML]="data.message"> </p>
    <div class="d-flex align-items-center justify-content-center mt-2">
      <button class="mr-2 {{data.btnCancelClass}}" mat-raised-button color="grey" (click)="close()"
        *ngIf="data.btnCancel">
        {{data.btnCancelTitle?data.btnCancelTitle:('swalMsg.cancel'|translate)}}
      </button>
      <button class="{{data.btnClass}}" mat-raised-button color="primary" [disabled]="loading" [class.loading]='loading'
        (click)="confirm()">
        {{data.btnTitle?data.btnTitle:('swalMsg.ok'|translate)}}
      </button>
    </div>
  </div>
</mat-dialog-content>
