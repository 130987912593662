export enum FieldTypes {
  EMPTY,
  DATE,
  LIST,
  NUMBER,
  CHECKBOX,
  TEXT,
  TEXTAREA,
  MULTIPLE_CHOICE,
  MULTIPLE_TEXTBOXES,
  FILE
}
